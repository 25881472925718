<template>
  <AlgoliaCurrentRefinements
    :included-attributes="[
      'al_menu.lvl0',
      'brand.title',
      'parent.name',
      'is_new',
      'is_sale',
      'black',
      ...attributes,
    ]"
  >
    <ul slot-scope="{ refinements, refine, createURL }" class="TagList TagList--border">
      <template v-for="item in refinements">
        <li
          v-for="refinement in item.refinements"
          :key="
            [refinement.attribute, refinement.type, refinement.value, refinement.operator].join(':')
          "
          class="TagList__tag TagList__tag--remove"
        >
          <a
            v-if="
              refinement.value !== '1' &&
                refinement.value !== 'true' &&
                refinement.value !== 'false'
            "
            :href="createURL(refinement)"
            @click.prevent="refine(refinement)"
          >
            <template v-if="refinement.type === 'hierarchical' || refinement.attribute === 'label'">
              {{ refinement.value }}
            </template>
            <template v-else-if="refinement.attribute === 'black'">
              {{ $t('blackweek') }}
            </template>
            <template v-else-if="refinement.attribute === 'brand.string'">
              {{ $t('brand') }}: {{ refinement.value.split('|')[0] }}
            </template>
            <template v-else> {{ $t(refinement.attribute) }}: {{ refinement.value }} </template>
          </a>
          <a v-else :href="createURL(refinement)" @click.prevent="refine(refinement)">
            {{ $t(refinement.attribute) }}
          </a>
        </li>
      </template>
    </ul>
  </AlgoliaCurrentRefinements>
</template>

<script>
import { CurrentRefinements as AlgoliaCurrentRefinements } from '@containers/Algolia';

const productFilterData = window['productFilterData'];

export default {
  name: 'CurrentRefinements',

  components: {
    AlgoliaCurrentRefinements,
  },

  data() {
    return {
      attributes:
        productFilterData !== null && productFilterData !== undefined
          ? Object.keys(productFilterData)
              .map(key =>
                Object.keys(productFilterData[key]).map(child => 'sheets.' + key + '.' + child),
              )
              .flat()
          : [],
    };
  },
};
</script>
