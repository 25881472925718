<template functional>
  <label class="LabelComponent">{{ props.text }}</label>
</template>

<script>
export default {
  name: 'Label',
  functional: true,
  props: {
    text: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
.LabelComponent {
  display: inline-block;
  margin-bottom: 7px !important;
  color: $color-black;
  font-weight: 700;
}
</style>
