<template>
  <AisIndex
    v-show="groupHits || showBanner"
    :index-name="settings.search.indices.groups"
    class="SearchDropdown__section CategoryResults"
  >
    <Hits
      v-slot="{ nbHits: groupNbHits, hits: groupHits, trackClick }"
      :limit="groupsCollapsed ? 8 : 30"
      :auto-hide="true"
      @change="onGroupChange"
    >
      <h2 class="SearchDropdown__sectionTitle">{{ $t('groups-title') }}:</h2>
      <a v-if="showBanner" class="CategoryResults__banner" href="/fyrvaerkeri/" title="">
        <ImageHandler
          :source="
            `//cdn.thg.dk/DAT/dom/img/search_fyrvaerkeri_banner_${
              settings.legacy.countryCode === 'NO' ? 'no' : 'da'
            }.jpg`
          "
          :title="$t('fireworks')"
        />
      </a>
      <div class="SearchGroups">
        <GroupCard
          v-for="group in groupHits.slice(0, groupsCollapsed ? 8 : 30)"
          :key="group.objectID"
          :group="group"
          class="SearchGroups__item"
          @click="trackClick(group.objectID)"
        />
      </div>
      <button
        v-if="groupsCollapsed && groupNbHits > 30"
        class="Button Button--small Button--transparent"
        @click.prevent="toggleGroupsCollapse"
      >
        {{ $t('show-more') }}
      </button>
      <button
        v-else-if="groupNbHits > 30"
        class="Button Button--small Button--transparent"
        @click.prevent="toggleGroupsCollapse"
      >
        {{ $t('show-less') }}
      </button>
    </Hits>
  </AisIndex>
</template>

<script>
import { AisIndex } from 'vue-instantsearch';

import { Hits } from '@components/Search';
import { GroupCard } from '@components/Cards';
import ImageHandler from '@components/ImageHandler';
import settings from '@settings';
export default {
  name: 'CategoryResults',

  components: {
    AisIndex,
    Hits,
    GroupCard,
    ImageHandler,
  },

  props: {
    onGroupChange: {
      required: true,
      type: Function,
    },
    groupHits: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      groupsCollapsed: true,
    };
  },

  created() {
    this.settings = settings;
    this.showBanner =
      settings.legacy.fireworks &&
      (settings.legacy.fireworks.presale || settings.legacy.fireworks.sale) &&
      ['mobileS', 'mobileM', 'mobileL', 'tablet'].indexOf(this.$mq) === -1;
  },

  methods: {
    toggleGroupsCollapse() {
      this.groupsCollapsed = !this.groupsCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.CategoryResults {
  .CategoryResults__banner {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
