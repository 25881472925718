<template functional>
  <ReferenceHits
    v-slot="{ nbHits: referenceNbHits, hits: referenceHits, currentReference }"
    @change="parent.onReferenceChange"
    @status="parent.onReferenceStatus"
  >
    <div v-if="referenceNbHits > 0" class="SearchDropdown__hits">
      <div class="SearchDropdown__info">
        {{
          parent.$tc('searchresults-refrence', referenceNbHits, {
            count: referenceNbHits.toLocaleString(),
          })
        }}
        <mark>{{ currentReference }}</mark
        >:
      </div>

      <ul>
        <!-- TODO: Click tracking for referenceHits? -->
        <SearchResultCompact
          v-for="hit in referenceHits"
          :key="hit.objectID"
          class="SearchResult"
          :product="hit"
          :highlight="false"
          :extended="parent.extendResults"
        />
      </ul>
    </div>

    <div class="SearchDropdown__hits">
      <Hits
        v-slot="{ hits, trackClick, nbHits }"
        tag="ul"
        hit-key="item_number"
        :filter-hits="referenceHits"
        :limit="parent.maxItems"
        @noHits="e => (referenceNbHits === 0 ? parent.onNoHits(e) : null)"
      >
        <template v-if="nbHits > 0">
          <div v-if="!(nbHits === 0 && referenceHits.length > 0)" class="SearchDropdown__info">
            <template v-if="parent.query">
              {{
                parent.$tc('found-products', nbHits === 0 ? 0 : parent.totalHits, {
                  count: parent.totalHits.toLocaleString(),
                })
              }}
            </template>
            <template v-else> {{ parent.$t('popular-products') }}: </template>
          </div>
          <template v-if="nbHits > 0">
            <SearchResultCompact
              v-for="hit in hits"
              :key="hit.objectID"
              class="SearchResult"
              :product="hit"
              :extended="parent.extendResults"
              @click="trackClick(hit.objectID)"
            />
          </template>
          <div
            v-if="parent.query && nbHits > 0 && nbHits > parent.maxItems"
            class="SearchDropdown__controls"
          >
            <a class="Button Button--small Button--light" :href="parent.searchUrl">{{
              parent.$t('show-all')
            }}</a>
          </div>
        </template>
      </Hits>
    </div>
  </ReferenceHits>
</template>

<script>
import { ReferenceHits } from '@components/Search';

export default {
  name: 'ReferenceHitsWrapper',
  components: {
    ReferenceHits,
  },
};
</script>
