var render = function (_h,_vm) {var _c=_vm._c;return _c('ReferenceHits',{on:{"change":_vm.parent.onReferenceChange,"status":_vm.parent.onReferenceStatus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var referenceNbHits = ref.nbHits;
var referenceHits = ref.hits;
var currentReference = ref.currentReference;
return [(referenceNbHits > 0)?_c('div',{staticClass:"SearchDropdown__hits"},[_c('div',{staticClass:"SearchDropdown__info"},[_vm._v(" "+_vm._s(_vm.parent.$tc('searchresults-refrence', referenceNbHits, { count: referenceNbHits.toLocaleString(), }))+" "),_c('mark',[_vm._v(_vm._s(currentReference))]),_vm._v(": ")]),_c('ul',_vm._l((referenceHits),function(hit){return _c('SearchResultCompact',{key:hit.objectID,staticClass:"SearchResult",attrs:{"product":hit,"highlight":false,"extended":_vm.parent.extendResults}})}),1)]):_vm._e(),_c('div',{staticClass:"SearchDropdown__hits"},[_c('Hits',{attrs:{"tag":"ul","hit-key":"item_number","filter-hits":referenceHits,"limit":_vm.parent.maxItems},on:{"noHits":function (e) { return (referenceNbHits === 0 ? _vm.parent.onNoHits(e) : null); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hits = ref.hits;
var trackClick = ref.trackClick;
var nbHits = ref.nbHits;
return [(nbHits > 0)?[(!(nbHits === 0 && referenceHits.length > 0))?_c('div',{staticClass:"SearchDropdown__info"},[(_vm.parent.query)?[_vm._v(" "+_vm._s(_vm.parent.$tc('found-products', nbHits === 0 ? 0 : _vm.parent.totalHits, { count: _vm.parent.totalHits.toLocaleString(), }))+" ")]:[_vm._v(" "+_vm._s(_vm.parent.$t('popular-products'))+": ")]],2):_vm._e(),(nbHits > 0)?_vm._l((hits),function(hit){return _c('SearchResultCompact',{key:hit.objectID,staticClass:"SearchResult",attrs:{"product":hit,"extended":_vm.parent.extendResults},on:{"click":function($event){return trackClick(hit.objectID)}}})}):_vm._e(),(_vm.parent.query && nbHits > 0 && nbHits > _vm.parent.maxItems)?_c('div',{staticClass:"SearchDropdown__controls"},[_c('a',{staticClass:"Button Button--small Button--light",attrs:{"href":_vm.parent.searchUrl}},[_vm._v(_vm._s(_vm.parent.$t('show-all')))])]):_vm._e()]:_vm._e()]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }