<template>
  <a :href="url" :title="title" class="ProductCardMini">
    <ImageHandler class="ProductCardMini__image" :source="image" :title="title" />
    <div class="ProductCardMini__details">
      <span class="ProductCardMini__title">{{ title }}</span>
      <div class="ProductCardMini__price"><Price :value="price" /></div>
    </div>
  </a>
</template>

<script>
import ImageHandler from '@components/ImageHandler';
import Price from '@components/Price';
export default {
  name: 'ProductCardMini',
  components: {
    ImageHandler,
    Price,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.ProductCardMini {
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  border: 1px solid #dedede;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  text-decoration: none;
  .ProductCardMini__image {
    flex: 1;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  .ProductCardMini__details {
    display: flex;
    flex: 2.5;
    flex-direction: column;
    justify-content: space-between;
  }
  .ProductCardMini__title {
  }
  .ProductCardMini__price {
    font-weight: bold;
  }
}
</style>
