<template>
  <div v-show="items.length > 0">
    <h2 class="SearchDropdown__sectionTitle">{{ $t('brands') }}</h2>
    <ul class="TagList">
      <li v-for="item in items" :key="item.value" class="TagList__tag">
        <a v-if="item.brand" :href="item.brand.url" :title="item.brand.name">
          <img v-if="item.brand.logo" :src="item.brand.logo" :alt="item.brand.name" />
          <template v-else>
            {{ item.brand.name }}
          </template>
        </a>
      </li>
      <li v-if="canToggleShowMore" class="TagList__showMore">
        <button :disabled="!canToggleShowMore" @click="toggleShowMore">
          {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AisRefinementList, AisIndex } from 'vue-instantsearch';

export default {
  name: 'SearchBrands',
  components: {
    AisRefinementList,
    AisIndex,
  },

  props: {
    items: {
      required: true,
      type: Array,
    },
    canToggleShowMore: {
      required: true,
      type: Boolean,
    },
    toggleShowMore: {
      required: true,
      type: Function,
    },
    isShowingMore: {
      required: true,
      type: Boolean,
    },
  },

  computed: {},
};
</script>
