<script>
import helpers from '@helpers';
import Tag from '@components/Tag';
import Label from '../Label';

export default {
  name: 'TagGroupAlgolia',
  functional: true,
  props: {
    refineMethod: {
      required: true,
    },
    items: {
      required: true,
    },
    label: {
      required: false,
      default: '',
    },
    unit: {
      required: false,
      default: '',
    },
  },
  render(createElement, context) {
    const { items, refineMethod, label, unit } = context.props;

    const mappedItems = items.map(item => (
      <li class={['TagGroup__item'].join(' ')} key={item.value}>
        <Tag
          brand={item.brand}
          label={item.label + helpers.product.printIfHasUnit(unit)}
          selected={item.isRefined}
          amount={item.count.toLocaleString()}
          action={e => refineMethod(item.value)}
        />
      </li>
    ));

    return (
      <div>
        {label && <Label text={label} />}
        <ul class="TagGroup">{mappedItems}</ul>
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.TagGroup {
  display: flex;
  margin-bottom: -10px; // Default styling for UL is margin-bottom: 10px;
  flex-wrap: wrap;
}

.Wrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;

  cursor: pointer;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: $color-prim-grey;
  margin: 0 10px 10px 0;
  color: $color-prim-grey-dark;

  &--selected {
    background-color: $color-prim-brand;
    color: $color-white;
  }

  &--disabled {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
