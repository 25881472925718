<template>
  <AisIndex :index-name="indexName">
    <slot name="before"></slot>
    <Hits
      v-slot="{ hit, hits, nbHits, insights, trackClick, trackConvension }"
      :tag="tag"
      :limit="limit"
      :class="hitsClass"
      @change="e => $emit('change', e)"
    >
      <slot
        :hit="hit"
        :hits="hits"
        :nbHits="nbHits"
        :insights="insights"
        :track-click="trackClick"
        :track-convension="trackConvension"
      />
    </Hits>
  </AisIndex>
</template>

<script>
import { Hits } from '@components/Search';
import { AisIndex } from 'vue-instantsearch';

export default {
  name: 'HitsIndex',
  components: {
    Hits,
    AisIndex,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },

    limit: {
      type: Number,
      default: -1,
    },
    indexName: {
      type: String,
      required: true,
    },
    hitsClass: {
      type: String,
      default: '',
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
