var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SearchSuggestions"},[_c('AisQueryRuleCustomData',{attrs:{"transform-items":_vm.transformRuleItems}}),(_vm.title)?_c('h2',{staticClass:"SearchSuggestions__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('HitsIndex',{staticClass:"SearchSuggestions__tags",attrs:{"tag":"ul","index-name":_vm.algoliaIndices.brands,"hits-class":"TagList TagList--dark","auto-hide":true},on:{"change":_vm.onBrandChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hit = ref.hit;
return [_vm._l((_vm.ruleSuggestions),function(suggestion,index){return _c('li',{key:index,staticClass:"TagList__tag"},[_c('a',{attrs:{"href":suggestion.url,"title":suggestion.name}},[(suggestion.icon)?_c('i',{class:suggestion.icon}):_vm._e(),_vm._v(" "+_vm._s(suggestion.name))])])}),_c('HitsIndex',{staticClass:"SearchSuggestions__tags",attrs:{"hits-tag":"ul","index-name":_vm.algoliaIndices.querySuggestions,"hits-class":"TagList TagList--dark","auto-hide":true,"limit":_vm.query.length === 0 ? 20 : 10},on:{"change":_vm.onQueryChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hits = ref.hits;
return [(_vm.query.length > 1 && hit)?_c('li',{staticClass:"TagList__tag"},[(hit.logo && hit.logo !== '')?_c('a',{attrs:{"href":hit.url,"title":hit.name}},[_c('span',[_vm._v(_vm._s(_vm.$t('search-in'))+" ")]),_c('img',{attrs:{"src":hit.logo,"alt":hit.name}})]):_c('a',{attrs:{"href":hit.url,"title":hit.name}},[_vm._v(_vm._s(_vm.$t('search-in'))+" "),_c('mark',[_vm._v(_vm._s(hit.name))])])]):_vm._e(),(_vm.query.length === 0 && _vm.settings.mode !== 'b2b')?_c('li',{staticClass:"TagList__tag"},[_c('a',{attrs:{"href":"/butikker","title":_vm.$t('opening-hours')}},[_vm._v(_vm._s(_vm.$t('opening-hours').toLowerCase()))])]):_vm._e(),(_vm.couldBeLicensplate)?_c('li',{staticClass:"TagList__tag"},[_c('a',{attrs:{"href":"","title":_vm.$t('search-car')},on:{"click":function($event){$event.preventDefault();return _vm.gotoCar($event)}}},[_vm._v(_vm._s(_vm.$t('search-car'))+" "),_c('mark',[_vm._v(_vm._s(_vm.query.toUpperCase()))])])]):_vm._e(),_vm._l((hits),function(queryHit){return [(
            queryHit.query.toLowerCase() !== _vm.brandSuggestion.toLowerCase() &&
              queryHit.query.toLowerCase() !== _vm.query.toLowerCase()
          )?_c('li',{key:queryHit.objectId,staticClass:"TagList__tag"},[_c('a',{attrs:{"href":_vm.createSearchUrl(queryHit.query)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('query-selected', queryHit.query)}}},[_c('AisHighlight',{attrs:{"attribute":"query","hit":queryHit,"class-names":{ 'ais-Highlight': '' }}})],1)]):_vm._e()]})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }