var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AlgoliaCurrentRefinements',{attrs:{"included-attributes":[
    'al_menu.lvl0',
    'brand.title',
    'parent.name',
    'is_new',
    'is_sale',
    'black' ].concat( _vm.attributes )},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var refinements = ref.refinements;
  var refine = ref.refine;
  var createURL = ref.createURL;
return _c('ul',{staticClass:"TagList TagList--border"},[_vm._l((refinements),function(item){return _vm._l((item.refinements),function(refinement){return _c('li',{key:[refinement.attribute, refinement.type, refinement.value, refinement.operator].join(':'),staticClass:"TagList__tag TagList__tag--remove"},[(
            refinement.value !== '1' &&
              refinement.value !== 'true' &&
              refinement.value !== 'false'
          )?_c('a',{attrs:{"href":createURL(refinement)},on:{"click":function($event){$event.preventDefault();return refine(refinement)}}},[(refinement.type === 'hierarchical' || refinement.attribute === 'label')?[_vm._v(" "+_vm._s(refinement.value)+" ")]:(refinement.attribute === 'black')?[_vm._v(" "+_vm._s(_vm.$t('blackweek'))+" ")]:(refinement.attribute === 'brand.string')?[_vm._v(" "+_vm._s(_vm.$t('brand'))+": "+_vm._s(refinement.value.split('|')[0])+" ")]:[_vm._v(" "+_vm._s(_vm.$t(refinement.attribute))+": "+_vm._s(refinement.value)+" ")]],2):_c('a',{attrs:{"href":createURL(refinement)},on:{"click":function($event){$event.preventDefault();return refine(refinement)}}},[_vm._v(" "+_vm._s(_vm.$t(refinement.attribute))+" ")])])})})],2)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }