<template>
  <div v-if="state">
    <AisQueryRuleCustomData :transform-items="setRulesData">
      <AisStats v-slot="{ nbHits }">
        <slot
          v-if="state"
          :query="query"
          :nbHits="nbHits"
          :rules-data="rulesData"
          :refine="debounceRefine"
          :search-url="createSearchUrl(query)"
          :go-search="goSearch"
          :rule-data="getRuleData"
          :stalled="stalled"
        />
      </AisStats>
    </AisQueryRuleCustomData>
  </div>
</template>

<script>
import { AisStats, AisQueryRuleCustomData } from 'vue-instantsearch';

import { createWidgetMixin } from 'vue-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';

import { EventBus } from '@services/eventBus';

import { createSearchUrl } from '@algolia';

export default {
  name: "SearchBox",
  components: { AisQueryRuleCustomData, AisStats },
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  props: {
    delay: {
      type: Number,
      default: 500,
    },
    role: {
      required: false,
      default: '',
    },
  },

  data() {
    return {
      debounceQueryTimer: undefined,
      rulesData: [],
      localQuery: '',
      createSearchUrl: createSearchUrl,
    };
  },
  computed: {
    stalled() {
      if (!this.state) {
        return true;
      }

      return this.query !== this.state.query || this.state.isSearchStalled;
    },
    widgetParams() {
      return {
        autofocus: true,
      };
    },
    query: {
      get() {
        return this.localQuery;
      },
      set(val) {
        this.localQuery = val;

        // Clear timer
        clearTimeout(this.debounceQueryTimer);

        // Set timer for refining value if state if defined
        this.debounceQueryTimer = setTimeout(() => {
          if (this.state) {
            this.state.refine(val);
          }
        }, this.delay);
      },
    },

    getRuleData() {
      return ruleName => {
        if (ruleName) {
          const rule = this.rulesData.find((item, index) => {
            return Boolean(item[ruleName]);
          });

          return rule ? rule[ruleName] : null;
        }

        return this.rulesData;
      };
    },
  },

  watch: {
    ['state.query'](newQuery, oldQuery) {
      if (newQuery !== this.query) {
        this.query = newQuery;
      }
    },

    stalled(isStalled) {
      if (this.role !== '') {
        // Emit global event, not avoid to many rerenders across algolia
        EventBus.$emit(this.role, isStalled);
      }
    },
  },

  created() {
    if (this.$route.query.query) {
      this.query = this.$route.query.query;
    }
  },

  destroyed() {
    clearTimeout(this.debounceQueryTimer);
  },

  methods: {
    setRulesData(data) {
      this.rulesData = data;
      return data;
    },

    goSearch(query) {
      window.location.href = this.createSearchUrl(query);
    },

    debounceRefine(query, immediately) {
      if (immediately) {
        this.state.refine(query);
      }
      this.query = query;
    },

    refine(query) {
      if (this.state) {
        this.state.refine();
      }
    },
  },
};
</script>
