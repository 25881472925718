import { createWidgetMixin } from 'vue-instantsearch';
import { connectHits } from 'instantsearch.js/es/connectors';

export default {
  name: 'HitsContainer',
  mixins: [createWidgetMixin({ connector: connectHits })],
  props: {
    tag: {
      type: String,
      default: 'div',
    },

    limit: {
      type: Number,
      default: -1,
    },

    filterHits: {
      type: Array,
      default() {
        return [];
      },
    },

    mergeHits: {
      type: Array,
      default() {
        return [];
      },
    },

    // The primary key of the hit used for filtering and merging with other hits (mergeHits+filterHits)
    hitKey: {
      type: String,
      default: 'item_number',
    },
  },

  data() {
    return {
      hitTimerout: null,
    };
  },

  computed: {
    hits() {
      if (!this.state) return [];
      let hits = this.state.hits;
      if (this.filterHits.length) {
        hits = this.filterOut(hits, this.filterHits, this.hitKey);
      }
      if (this.mergeHits.length) {
        hits = this.mergeWith(this.mergeHits, hits, this.hitKey);
      }
      return this.limit !== -1 ? hits.slice(0, this.limit) : hits;
    },
    hit() {
      // Returns the first hit

      if (this.state && this.state.hits && this.state.hits.length > 0) return this.state.hits[0];
      return null;
    },

    insights() {
      return this.state ? this.state.instantSearchInstance.insightsClient : null;
    },

    queryID() {
      return this.state ? this.state.results.queryID : null;
    },

    index() {
      return this.state ? this.state.results.index : null;
    },
  },

  watch: {
    hit(newHit, oldHit) {
      //getByRef

      //this.debouncedGetAnswer()

      this.$emit('change', { hit: newHit, nbHits: this.state.hits.length, state: this.state });
    },

    'state.results.hits': function(hits, oldHits) {
      clearTimeout(this.hitTimerout);
      if (hits.length === 0) {
        // Must be run on created?!
        this.hitTimerout = setTimeout(this.onNoHits, 1500);
      }
    },
  },

  beforeDestroy() {
    clearTimeout(this.hitTimerout);
  },
  render() {
    return this.$scopedSlots.default({
      // State
      hits: this.hits,
      nbHits: this.hits.length,
      insights: this.insights,
      hit: this.hit,
      trackConvension: this.trackConvension,
      trackClick: this.trackClick,
    });
  },
  methods: {
    onNoHits() {
      this.$emit('noHits', { ...this.state.results });
    },

    mergeWith(a, b, prop) {
      const reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
      return reduced.concat(b);
    },

    filterOut(a, b, prop) {
      const reduced = a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
      return reduced;
    },

    getHitPosition(objectID) {
      const objectIndex = this.hits.findIndex(hit => {
        return hit.objectID === objectID;
      });
      return objectIndex + 1;
    },
    /**
     *  Algolia events
     */
    trackClick(objectID, eventName, notSearchResult) {
      const eventObj = {
        eventName: eventName || 'Search Result Clicked',
        objectIDs: [objectID],
        queryID: this.queryID,
        positions: [this.getHitPosition(objectID)],
      };
      if (this.index) {
        eventObj.index = this.index;
      }

      this.insights('clickedObjectIDsAfterSearch', eventObj);
    },
    /**
     *  Algolia events
     */
    trackConvension(objectID, eventName, notSearchResult) {
      const eventObj = {
        eventName: eventName || 'Search Result Converted',
        objectIDs: [objectID],
        queryID: this.queryID,
      };
      if (this.index) {
        eventObj.index = this.index;
      }

      this.insights('convertedObjectIDsAfterSearch', eventObj);
    },
  },
};
