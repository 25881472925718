<template functional>
  <div class="SearchResult__price">
    <div v-if="props.bundleOffers.length > 0" class="SearchResult__offers">
      <div v-for="(offer, index) in props.bundleOffers" :key="index" class="SearchResult__offer">
        {{
          parent.$t('bundle-text', [
            offer.quantity,
            parent.$n(offer.price * offer.quantity, 'currency'),
          ])
        }}
      </div>
    </div>

    <Price
      v-if="props.isOnSale"
      class="SearchResult__compareAtPrice"
      :value="props.compareAtPrice"
    />

    <span
      v-if="$options.settings.mode === 'b2b' && props.productStatus && props.productStatus.price"
      class="txt price"
    >
      <div class="price-holder">
        <span class="customer-price"><Price :value="props.productStatus.detailPrice"/></span>

        <div class="price-hidden">
          <Price class="hidden" :value="props.productStatus.price" />
        </div>
      </div>
    </span>
    <Price v-else :value="props.price" />
  </div>
</template>

<script>
import Price from '@components/Price';
import settings from '@settings';

export default {
  name: 'SearchResultCompactPrice',

  functional: true,

  components: {
    Price,
  },

  settings,

  props: {
    bundleOffers: {
      required: true,
    },
    compareAtPrice: {
      required: true,
    },
    price: {
      required: true,
    },
    isOnSale: {
      required: true,
    },
    productStatus: {
      required: true,
    },
  },
};
</script>
