<script>
import helpers from '@helpers';
import Choice from '@components/Choice';
import Label from '../Label';
import { ListTransition } from '@transitions';

export default {
  name: 'ChoiceGroupAlgolia',
  functional: true,
  components: {
    ListTransition,
  },
  props: {
    refineMethod: {
      required: true,
    },
    items: {
      required: true,
    },
    shouldTransition: {
      required: false,
      default: false,
    },
    label: {
      required: false,
      default: '',
    },
    unit: {
      required: false,
      default: '',
    },
  },

  render(createElement, context) {
    const { items, refineMethod, shouldTransition, label, unit } = context.props;

    const mappedItems = items.map(item => (
      <li class="Checklist__item" key={item.value}>
        <Choice
          brand={item.brand}
          label={item.label + helpers.product.printIfHasUnit(unit)}
          selected={item.isRefined}
          amount={item.count.toLocaleString()}
          action={e => refineMethod(item.value)}
        />
      </li>
    ));

    return shouldTransition ? (
      <div>
        {label && <Label text={label} />}
        <ListTransition tag="ul" class="Checklist Checklist--gapped">
          {mappedItems}
        </ListTransition>
      </div>
    ) : (
      <div>
        {label && <Label text={label} />}
        <ul class="Checklist Checklist--gapped">{mappedItems}</ul>
      </div>
    );
  },
};
</script>
