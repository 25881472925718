var render = function (_h,_vm) {var _c=_vm._c;return _c('BlockCollapse',{attrs:{"title":"Kategori","expanded":true}},[_c('AisHierarchicalMenu',{attrs:{"attributes":['al_menu.lvl0', 'al_menu.lvl1', 'al_menu.lvl2', 'al_menu.lvl3'],"limit":999,"class-names":{
      'ais-HierarchicalMenu': 'HierarchicalMenu',
      'ais-HierarchicalMenu-list': 'HierarchicalMenu__list',
      'ais-HierarchicalMenu-item': 'HierarchicalMenu__item',
      'ais-HierarchicalMenu-item--selected': 'is-selected',
      'ais-HierarchicalMenu-link': 'HierarchicalMenu__link',
      'ais-HierarchicalMenu-label': 'HierarchicalMenu__label',
      //'ais-HierarchicalMenu-count': $options.countClass(),
      'ais-HierarchicalMenu-count': 'HierarchicalMenu__count--hidden',
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }