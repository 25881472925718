<template>
  <div>
    <slot :hits="hits" :nbHits="nbHits" :currentReference="currentReference" />
  </div>
</template>
<script>
// Read more on connectors here: https://community.algolia.com/instantsearch.js/v2/connectors/connectAutocomplete.html#struct-Index
import { createWidgetMixin } from 'vue-instantsearch';
import { connectStats } from 'instantsearch.js/es/connectors';

import debounce from 'debounce';

import settings from '@settings';

import { getByRef } from '@services/thansen/products/';

export default {
  name: 'AisReferenceHits',
  mixins: [createWidgetMixin({ connector: connectStats })],
  props: {},
  data: function() {
    return {
      refrenceHit: null,
      requestRefrenceHits: debounce(this.fetchRefrenceHits, 200),
      status: '',
    };
  },
  computed: {
    hits() {
      return this.refrenceHit && this.refrenceHit.items ? this.refrenceHit.items : [];
    },
    currentReference() {
      return this.refrenceHit && this.refrenceHit.ref_number ? this.refrenceHit.ref_number : '';
    },
    nbHits() {
      return this.hits.length;
    },
  },
  watch: {
    'state.query': function(newQuery, oldQuery) {
      if (newQuery !== oldQuery) {
        this.refrenceHit = null;
      }
      if (settings.search.referenceSearchEnabled) {
        this.requestRefrenceHits();
      }
    },
    status(status, oldStatus) {
      this.$emit('status', { status });
    },
    hits(hits) {
      this.$emit('change', { nbHits: hits.length, state: this.state });
    },
  },
  created() {
    this.status = 'updated';
  },
  methods: {
    fetchRefrenceHits() {
      if (this.state && this.state.query.length > 3) {
        this.status = 'updating';
        getByRef(this.state.query)
          .then(refrenceHit => {
            if (refrenceHit && refrenceHit.ref_number && refrenceHit.ref_number !== 'null') {
              // Hotfix: API returns a "null" string. The last if needs to be removed.
              this.refrenceHit = refrenceHit;
            } else {
              this.refrenceHit = null;
            }
            this.status = 'updated';
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
};
</script>
