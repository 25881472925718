<template>
  <div>
    <AisRefinementList
      v-slot="{ refine, canToggleShowMore, toggleShowMore, isShowingMore, items, searchForItems }"
      show-more
      searchable
      :limit="18"
      :show-more-limit="999"
      attribute="brand.string"
      :transform-items="brandsTransformItems"
    >
      <BlockCollapse v-show="searchInput || items.length > 1" :title="$t('brand')" :expanded="true">
        <Input
          v-if="canToggleShowMore || searchInput"
          id="BrandFilterSearch"
          v-model="searchInput"
          :placeholder="$t('search-in') + ' ' + $t('brands').toLowerCase()"
          @input="value => searchForItems(value)"
        />
        <p v-if="searchInput && items.length === 0">{{ $t('search.noResults') }}</p>
        <ChoiceGroup :items="items" :refine-method="refine" should-transition="true" />

        <div v-if="canToggleShowMore" class="Checklist__showMore">
          <button
            class="Button Button--small Button--light"
            :disabled="!canToggleShowMore"
            @click="toggleShowMore"
          >
            {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
          </button>
        </div>
      </BlockCollapse>
    </AisRefinementList>
  </div>
</template>

<script>
import { brandStringToObject } from '@helpers/algolia';
import Choice from '@components/Choice';
import Select from '@components/Select';
import { ChoiceGroup } from '@components/Algolia';
import { SearchIndicator, searchIndicatorSizes, BlockCollapse } from '@components/UI';
import { AisRefinementList } from 'vue-instantsearch';
import { mapActions, mapGetters, mapState } from 'vuex';

import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'BrandFilter',
  components: {
    Select,
    Input,
    ChoiceGroup,
    AisRefinementList,
    BlockCollapse,
    SearchIndicator,
  },

  data() {
    return {
      searchInput: '',
      searchIndicatorSizes,
    };
  },

  computed: {},

  created() {},

  methods: {
    brandsTransformItems(items) {
      // Set items to local state
      //this.items = items;

      // Return items to Algolia
      return items.map(item => {
        return {
          ...item,
          brand: brandStringToObject(item.value),
        };
      });
    },
  },
};
</script>
