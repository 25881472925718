// Read more on connectors here: https://community.algolia.com/instantsearch.js/v2/connectors/connectAutocomplete.html#struct-Index
import { createWidgetMixin } from 'vue-instantsearch';
import { connectPagination } from 'instantsearch.js/es/connectors';

export default {
  name: 'AlgoliaPagination',
  mixins: [createWidgetMixin({ connector: connectPagination })],
  props: {
    padding: {
      type: Number,
      default: 2,
      validator(value) {
        return value > 0;
      },
    },
  },
  computed: {
    refine() {
      if (this.state) {
        return this.state.refine;
      }
    },
    createURL() {
      if (this.state) {
        return this.state.createURL;
      }
    },
    currentRefinement() {
      if (this.state) {
        return this.state.currentRefinement;
      }
    },
    nbHits() {
      if (this.state) {
        return this.state.nbHits;
      }
    },
    nbPages() {
      if (this.state) {
        return this.state.nbPages;
      }
    },
    pages() {
      if (this.state) {
        return this.state.pages;
      } else {
        return [];
      }
    },
    isFirstPage() {
      if (this.state) {
        return this.state.isFirstPage;
      }
    },
    isLastPage() {
      if (this.state) {
        return this.state.isLastPage;
      }
    },
    widgetParams() {
      return {
        padding: this.padding,
      };
    },
  },
  render() {
    return this.$scopedSlots.default({
      createURL: this.createURL,
      currentRefinement: this.currentRefinement,
      nbHits: this.nbHits,
      nbPages: this.nbPages,
      pages: this.pages,
      isFirstPage: this.isFirstPage,
      isLastPage: this.isLastPage,
      refine: this.refine,
    });
  },
};
