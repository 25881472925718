var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AisRefinementList',{attrs:{"show-more":"","searchable":_vm.searchable,"attribute":"type","show-more-limit":999,"sort-by":['isRefined', 'name:asc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
var items = ref.items;
var canToggleShowMore = ref.canToggleShowMore;
var toggleShowMore = ref.toggleShowMore;
var isShowingMore = ref.isShowingMore;
var searchForItems = ref.searchForItems;
return [_c('BlockCollapse',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchInput || items.length > 1),expression:"searchInput || items.length > 1"}],attrs:{"title":_vm.$t('type'),"expanded":true}},[(_vm.searchable)?[(canToggleShowMore || _vm.searchInput)?_c('Input',{attrs:{"id":"TypeFilterSearch","placeholder":_vm.$t('search-in') + ' ' + _vm.$t('types').toLowerCase()},on:{"input":function (value) { return searchForItems(value); }},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}):_vm._e(),(_vm.searchInput && items.length === 0)?_c('p',[_vm._v(_vm._s(_vm.$t('search.noResults')))]):_vm._e()]:_vm._e(),_c('ChoiceGroup',{attrs:{"items":items,"refine-method":refine,"should-transition":"true"}}),(canToggleShowMore)?_c('div',{staticClass:"Checklist__showMore"},[_c('button',{staticClass:"Button Button--small Button--light",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? _vm.$t('show-more') : _vm.$t('show-less'))+" ")])]):_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }