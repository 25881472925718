<template>
  <!--
    FilterMetoder:
    1 Checkbox (Vue: ChoiceList)
    2 Radiobutton (Vue: Select)
    3 Selectbox (Vue: Select)
    4 On/Off buttons (Vue: TagGroup)
    5 Rangeslider (Vue: Slider)
    6 Rangeslider+Input
    7 Input (Not supported)
    8 Toggle (Vue: Toggle)
  -->

  <div v-if="hasKeys">
    <BlockCollapse :title="$t('specifications')" :expanded="true">
      <template v-for="(ark, arkPropertyName) in filters">
        <template v-if="!isSheetExcluded(arkPropertyName)">
          <template v-for="(felt, feltPropertyName) in ark">
            <template v-if="!isFieldExcluded(arkPropertyName, feltPropertyName)">
              <!-- Checkbox -->
              <template v-if="felt.type == 1">
                <AisRefinementList
                  :key="feltPropertyName"
                  v-slot="{
                    items,
                    refine,
                    canToggleShowMore,
                    toggleShowMore,
                    isShowingMore,
                    canRefine,
                  }"
                  show-more
                  :show-more-limit="40"
                  :sort-by="sortNumericAndAlphabeticallyAsc"
                  :attribute="'sheets.' + arkPropertyName + '.' + feltPropertyName"
                >
                  <div v-show="items.length > 1">
                    <ChoiceGroup
                      v-if="canRefine"
                      :label="felt.name"
                      :unit="felt.unit"
                      :items="items"
                      :refine-method="refine"
                      :should-transition="true"
                    />
                    <div v-if="canToggleShowMore" class="ShowMoreButton">
                      <button
                        class="Button Button--small Button--light"
                        :disabled="!canToggleShowMore"
                        @click="toggleShowMore"
                      >
                        {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
                      </button>
                    </div>
                  </div>
                </AisRefinementList>
              </template>

              <!-- Radio button -->
              <template v-if="felt.type == 2 || felt.type == 3">
                <AisMenu
                  :key="feltPropertyName"
                  v-slot="{ refine, items, canRefine }"
                  :limit="120"
                  :sort-by="['name:asc']"
                  :attribute="'sheets.' + arkPropertyName + '.' + feltPropertyName"
                >
                  <div>
                    <template v-if="canRefine">
                      <Select
                        :label="felt.name"
                        :action="refine"
                        :items="items"
                        :unit="felt.unit"
                      />
                    </template>
                  </div>
                </AisMenu>
              </template>

              <!-- On/Off -->
              <template v-if="felt.type == 4">
                <AisRefinementList
                  :key="feltPropertyName"
                  v-slot="{
                    items,
                    refine,
                    canToggleShowMore,
                    toggleShowMore,
                    isShowingMore,
                    canRefine,
                  }"
                  show-more
                  :limit="20"
                  :show-more-limit="40"
                  :sort-by="sortNumericAndAlphabeticallyAsc"
                  :attribute="'sheets.' + arkPropertyName + '.' + feltPropertyName"
                >
                  <div>
                    <TagGroup
                      v-if="canRefine"
                      :label="felt.name"
                      :unit="felt.unit"
                      :items="items"
                      :refine-method="refine"
                      should-transition="true"
                    />
                    <div v-if="canToggleShowMore" class="ShowMoreButton">
                      <button
                        class="Button Button--small Button--light"
                        :disabled="!canToggleShowMore"
                        @click="toggleShowMore"
                      >
                        {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
                      </button>
                    </div>
                  </div>
                </AisRefinementList>
              </template>

              <!-- Rangeslider -->
              <template v-if="felt.type == 5 || felt.type == 6">
                <AisRangeInput
                  :key="feltPropertyName"
                  v-slot="{ currentRefinement, range, refine }"
                  :attribute="'sheets.' + arkPropertyName + '.' + feltPropertyName"
                >
                  <Slider
                    :label="felt.name"
                    :value="toValue(currentRefinement, range)"
                    :action="value => refine({ min: value[0], max: value[1] })"
                    :min="range.min"
                    :max="range.max"
                    :unit="felt.unit"
                  />
                </AisRangeInput>
              </template>

              <!-- Toggle -->
              <template v-if="felt.type == 8">
                <AisToggleRefinement
                  :key="feltPropertyName"
                  v-slot="{
                    canRefine,
                    value,
                    refine,
                    createURL,
                  }"
                  :on="true"
                  :attribute="'sheets.' + arkPropertyName + '.' + feltPropertyName"
                  :label="felt.name"
                  class="FirstGridChild"
                >
                  <div>
                    <div v-if="canRefine" class="Input">
                      <Toggle
                        :label="felt.name"
                        :selected="value.isRefined"
                        :amount="value.count || 0"
                        :action="() => refine(value)"
                      />
                    </div>
                  </div>
                </AisToggleRefinement>
              </template>
            </template>
          </template>
        </template>
      </template>
    </BlockCollapse>
  </div>
</template>

<script>
import Toggle from '@components/Toggle';
import Select from '@components/Select';
import { SearchIndicator, searchIndicatorSizes, BlockCollapse } from '@components/UI';
import { TagGroup, ChoiceGroup } from '@components/Algolia';
import { AisRefinementList, AisMenu, AisRangeInput, AisToggleRefinement } from 'vue-instantsearch';
import { Slider } from '@components/Search';
import { orderBy } from 'lodash';
import helpers from '@helpers';

export default {
  name: 'SpecificationsFilter',
  components: {
    Toggle,
    BlockCollapse,
    AisToggleRefinement,
    AisRefinementList,
    AisMenu,
    AisRangeInput,
    TagGroup,
    ChoiceGroup,
    Select,
    Slider,
    SearchIndicator,
  },
  props: {
    excludedAttrs: {
      default: () => {
        return [];
      },
      type: Array,
    },
  },

  data() {
    return {
      filters: Object.freeze(window['productFilterData'] || {}),
      searchIndicatorSizes: Object.freeze(searchIndicatorSizes),
    };
  },

  computed: {
    hasKeys() {
      return Object.keys(this.filters).length > 0;
    },
  },

  methods: {
    sortNumericAndAlphabeticallyAsc: function(a, b) {
      // Regex
      const onlyNumber = /^-?\d+\.?\d*$/;

      // Get names
      let nameA = a.name;
      let nameB = b.name;

      // Get refinements
      const isRefinedA = a.isRefined;
      const isRefinedB = b.isRefined;

      // If this a number in a string type.
      // ...if so, convert it.
      if (onlyNumber.test(nameA)) {
        nameA = parseFloat(nameA);
      }
      if (onlyNumber.test(nameB)) {
        nameB = parseFloat(nameB);
      }

      // Handle when refinements selections
      if (isRefinedA && isRefinedB) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else if (isRefinedB) {
        return 1;
      } else if (isRefinedA) {
        return -1;
      }

      // Perform sorting for items not refined
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },

    isSheetExcluded(sheet) {
      // If we check for an entire sheet
      return (
        this.excludedAttrs.find(item => item.sheet === sheet && item.fields === undefined) !==
        undefined
      );
    },
    isFieldExcluded(sheet, field) {
      // If we check for a single field
      return (
        this.excludedAttrs.find(item => {
          return (
            item.sheet == sheet && (item.fields === undefined || item.fields.indexOf(field) > -1)
          );
        }) !== undefined
      );
    },
    sortByOrder: content => orderBy(content, ['order']),
    toValue(value, range) {
      return helpers.algolia.rangeToValue(value, range);
    },
  },
};
</script>

<style lang="scss" scoped>
.FirstGridChild {
  order: -1;
}
.ShowMoreButton {
  padding-top: 20px;
}
</style>
