<template>
  <li class="SearchResult">
    <a :href="product.url" :title="product.title" @click="$emit('click', product)">
      <div class="SearchResult__image">
        <ImageComponent :src="product.image" :alt="product.title" />
      </div>

      <div class="SearchResult__description">
        <AisHighlight
          v-if="highlight"
          attribute="title"
          :hit="product"
          :class-names="{ 'ais-Highlight': '' }"
        />

        <span v-else>{{ product.title }}</span>

        <div class="SearchResult__details">
          <AisHighlight
            v-if="highlight"
            attribute="item_number"
            :hit="product"
            :class-names="{ 'ais-Highlight': '' }"
          />

          <span v-else>{{ product.item_number }}</span>

          <div v-if="settings.mode === 'b2b' && productStatus && productStatus.priceCode">
            {{ productStatus.priceCode }}
          </div>

          <div
            v-if="settings.mode === 'b2b' && statusMarkup"
            class="SearchResult__status DeliveryStatus"
            v-html="statusMarkup"
          ></div>
        </div>

        <Labels
          :is-news="product.is_new"
          :is-demo="product.is_demo"
          :popularity="product.popularity"
          :is-on-sale="sale"
          :label="product.label"
          :sale-percent="salePercent"
          :label-type="labelType"
          :is-clearance="isClearance"
        />
      </div>

      <div class="SearchResult__prices">
        <ProductPrice
          :bundle-offers="bundleOffers"
          :compare-at-price="product.compare_at_price"
          :product-status="productStatus"
          :price="price"
          :is-on-sale="sale"
        />

        <Buy
          v-if="extended"
          class="SearchResult__buy"
          :product="product"
          location="Søgning topbar"
          :enable-amount="settings.mode === 'b2b'"
          size="small"
        />
      </div>
    </a>
  </li>
</template>

<script>
import helpers from '@helpers';
import settings from '@settings';
import { mapGetters, mapActions } from 'vuex';
import { AisHighlight } from 'vue-instantsearch';
import debounce from 'debounce';

import ImageComponent from '@components/ImageComponent';
import Price from '@components/Price';
import { Buy } from '@components/Buttons';

import Labels from './components/Labels';
import ProductPrice from './components/ProductPrice';

export default {
  name: 'SearchResultCompact',

  components: {
    AisHighlight,
    Buy,
    Price,
    Labels,
    ProductPrice,
    ImageComponent,
  },

  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    extended: {
      type: Boolean,
      defalut: false,
    },
    highlight: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      requestProductStatus: debounce(this.checkFetchProductStatus, 400),
      statusFetched: false,
      settings,
    };
  },

  computed: {
    ...mapGetters({
      getProductStatus: 'products/getProductStatusByProductNode',
      getProductStatusStatus: 'products/getProductStatusStatus',
    }),
    productStatus() {
      if (this.product && typeof this.product.node_id !== 'undefined')
        return this.getProductStatus(this.product.node_id);
      return null;
    },
    productStatusStatus() {
      if (this.product && typeof this.product.node_id !== 'undefined')
        return this.getProductStatusStatus(this.product.node_id);
      return '';
    },
    statusMarkup() {
      if (this.productStatus && this.productStatus.stockInfoList) {
        return window.createStatusMarkup(JSON.parse(JSON.stringify(this.productStatus)));
      }
      return '';
    },
    label() {
      return this.product.label;
    },
    price() {
      return this.product.price;
    },
    sale() {
      return helpers.product.onSale(
        this.product.is_sale,
        this.product.price,
        this.product.compare_at_price,
      );
    },
    labelType() {
      return helpers.product.labelType(this.product);
    },
    salePercent() {
      return Math.round((1 - this.product.price / this.product.compare_at_price) * 100);
    },
    bundleOffers() {
      return this.product.offers
        ? this.product.offers.filter(offer => offer['type'] === 'bundle')
        : [];
    },
    buyBtnObj() {
      return {
        c: String(window.globalvar_c),
        pn: String(this.product.node_id),
        vn: String(this.product.item_number),
        btnLocation: 'search',
        vname: this.product.title,
      };
    },
    isClearance() {
      return helpers.product.isClearance(this.product.label);
    },
  },

  beforeDestroy() {
    this.requestProductStatus.clear();
  },

  mounted() {
    if (this.settings.mode === 'b2b') this.requestProductStatus();
  },

  methods: {
    ...mapActions({
      fetchProductStatus: 'products/fetchProductStatus',
    }),
    onAddToCart() {
      this.$emit('add-to-cart', this.product);
    },
    checkFetchProductStatus() {
      this.fetchProductStatus(this.product.node_id); // Will check it the data is expired and then update
    },
  },
};
</script>
