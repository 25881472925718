<script>
import helpers from '@helpers';
import settings from '@settings';

const stripAmount = amount => {
  // Method for stripping parenthesis from the amount
  return amount !== null ? amount.toString().replace(/[()]/g, '') : '';
};

export default {
  name: 'Choice',
  functional: true,
  props: {
    label: {
      required: true,
      type: String,
    },
    selected: {
      required: true,
      type: Boolean,
    },
    colorLabel: {
      default: '',
      type: String,
    },
    amount: {
      required: false,
      type: [String, Number],
      default: '',
    },
    action: {
      default: value => {
        if (settings.testmode) {
          console.log(
            'Value recieved. Remember to apply a method on the "action" prop for handling value change. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    brand: {
      required: false,
    },
  },

  render(createElement, context) {
    // Decontruct props
    let { amount } = context.props;
    const { label, selected, colorLabel, action, disabled, brand } = context.props;

    // Set amount, minus parenthesis
    amount = stripAmount(amount);

    const brandImage = brand !== undefined ? helpers.files.returnSrcIfHasExt(brand.logo) : '';

    return (
      <div
        aria-disabled={disabled ? 'true' : 'false'}
        class={['Wrapper', disabled ? 'Wrapper--disabled' : ''].join(' ')}
        tabIndex={0}
        vOn:keydown={key => {
          if (key.keyCode === 13 || key.code === 'Enter') {
            // Only run the action if the component isn't set to be disabled
            if (disabled !== true) {
              action(!selected);
            }
          }
        }}
        onClick={() => {
          // Only run the action if the component isn't set to be disabled
          if (disabled !== true) {
            action(!selected);
          }
        }}
      >
        {/* Input field for screen readers */}
        <input type="checkbox" class="NativeCheck" checked={selected} aria-hidden="false" />
        {/* Checkbox selected/unselected */}
        {selected === true ? (
          <div class="Checkbox Checkbox--checked" aria-hidden="true"></div>
        ) : (
          <div class="Checkbox" aria-hidden="true"></div>
        )}
        {/* Color label if available */}
        {colorLabel !== '' ? <div class={`ColorLabel ColorLabel--${colorLabel}`}></div> : null}
        {/* Brand, if available */}
        {brandImage !== '' ? (
          <div class="Brand">
            <img src={brand.logo} aria-label={brand.name} title={brand.name} />
          </div>
        ) : null}
        {/* Text label */}
        <div class={['Label', brandImage !== '' ? 'Label--companion' : null].join(' ')}>
          <div class="Label__inner">{brand && brand.name ? brand.name : label}</div>
        </div>

        {settings.testmode && amount && <span>({amount})</span>}
      </div>
    );
  },
};
</script>

<style scoped lang="scss">
.Wrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;

  &--disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.NativeCheck {
  display: none;
}

.Label {
  flex-grow: 1;
  color: $color-black;
  overflow: hidden;

  &--companion {
    color: inherit;
    font-size: 0.78em;
    display: flex;
    align-items: center;
  }

  &__inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.Amount {
  flex-grow: 0;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 1em;
}

.ColorLabel {
  flex-grow: 0;
  margin-right: 10px;

  margin-left: -3px;

  height: 20px;
  width: 7px;
  border-radius: 2px;

  &--new {
    background: $color-price-new;
  }

  &--demo {
    background: $color-price-demo;
  }

  &--sale {
    background: $color-price-sale;
  }

  &--outlet {
    background: $color-price-outlet;
  }
  &--black {
    background: black;
  }
}

.Checkbox {
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid #acafb1;
  border-radius: 0.22em;
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &--checked {
    &:before {
      content: '\f00c';

      width: 16px;
      height: 16px;
      font-size: 16px;
      font-family: 'Font Awesome 5 Pro';
      color: black;
      line-height: 16px;
    }
  }
}

.Brand {
  flex-shrink: 0;
  margin-right: 10px;

  > img {
    height: 16.8px;
  }
}
</style>
