<template functional>
  <Hits v-slot="{ hits, trackClick, nbHits }" :limit="parent.maxItems" @noHits="parent.onNoHits">
    <template v-if="nbHits > 0">
      <div v-if="parent.query" class="SearchDropdown__info">
        {{ parent.$tc('found-products', nbHits, { count: nbHits.toLocaleString() }) }}
      </div>
      <div v-else class="SearchDropdown__info">
        {{ parent.$t('popular-products') }}
      </div>
      <ul v-if="nbHits > 0" class="SearchDropdown__hits">
        <SearchResultCompact
          v-for="hit in hits"
          :key="hit.objectID"
          class="SearchResult"
          :product="hit"
          :extended="extendResults"
          @click="trackClick(hit.objectID)"
        />
      </ul>
      <div
        v-if="parent.query && nbHits > 0 && nbHits > parent.maxItems"
        class="SearchDropdown__controls"
      >
        <a class="Button Button--small Button--light" :href="parent.searchUrl">{{
          parent.$t('show-all')
        }}</a>
      </div>
    </template>
  </Hits>
</template>

<script>
export default {
  name: 'RegularHitsWrapper',
  functional: true,
};
</script>
