<template>
  <div>
    <AisRefinementList
      v-slot="{ refine, items, canToggleShowMore, toggleShowMore, isShowingMore, searchForItems }"
      show-more
      searchable
      :limit="10"
      attribute="parent.name"
      :show-more-limit="40"
      :sort-by="['isRefined', 'name:asc']"
    >
      <BlockCollapse v-if="items" title="Gruppe navn" :expanded="false">
        <Input
          v-if="canToggleShowMore || searchNameInput"
          id="ParentNameFilterSearch"
          v-model="searchNameInput"
          placeholder="Søg gruppe navn"
          @input="value => searchForItems(value)"
        />
        <p v-if="searchNameInput && items.length === 0">{{ $t('search.noResults') }}</p>

        <ChoiceGroup :items="items" :refine-method="refine" should-transition="true" />

        <div v-if="canToggleShowMore" class="Checklist__showMore">
          <button
            class="Button Button--small Button--light"
            :disabled="!canToggleShowMore"
            @click="toggleShowMore"
          >
            {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
          </button>
        </div>
      </BlockCollapse>
    </AisRefinementList>

    <AisRefinementList
      v-slot="{ refine, items, canToggleShowMore, toggleShowMore, isShowingMore, searchForItems }"
      show-more
      searchable
      :limit="4"
      attribute="parent.node_id"
      :show-more-limit="40"
      :sort-by="['isRefined', 'name:asc']"
    >
      <BlockCollapse v-if="items" title="Gruppe node" :expanded="false">
        <Input
          v-if="canToggleShowMore || searchNodeInput"
          id="ParentNodeIdFilterSearch"
          v-model="searchNodeInput"
          placeholder="Søg efter node id"
          @input="value => searchForItems(value)"
        />
        <p v-if="searchNodeInput && items.length === 0">{{ $t('search.noResults') }}</p>

        <ChoiceGroup :items="items" :refine-method="refine" should-transition="true" />

        <div v-if="canToggleShowMore" class="Checklist__showMore">
          <button
            class="Button Button--small Button--light"
            :disabled="!canToggleShowMore"
            @click="toggleShowMore"
          >
            {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
          </button>
        </div>
      </BlockCollapse>
    </AisRefinementList>
  </div>
</template>

<script>
import Choice from '@components/Choice';
import Select from '@components/Select';
import { ChoiceGroup } from '@components/Algolia';
import { SearchIndicator, searchIndicatorSizes, BlockCollapse } from '@components/UI';
import { AisRefinementList } from 'vue-instantsearch';
import { mapActions, mapGetters, mapState } from 'vuex';

import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'TypeFilter',
  components: {
    Select,
    ChoiceGroup,
    Input,
    AisRefinementList,
    BlockCollapse,
    SearchIndicator,
  },

  data() {
    return {
      searchNameInput: '',
      searchNodeInput: '',
      items: [],
    };
  },

  computed: {},

  created() {
    this.searchIndicatorSizes = searchIndicatorSizes;
  },

  methods: {},
};
</script>
