<template>
  <ul class="SortBy">
    <template v-for="(item, index) in items">
      <li v-if="!(hideDefault && index === 0)" :key="item.value" class="SortBy__item">
        <Button
          class="SortBy__button"
          :color="buttonColor"
          :size="buttonSizes.SMALL"
          :disabled="state && item.value == state.currentRefinement"
          :active="state && item.value == state.currentRefinement"
          @click="() => state.refine(item.value)"
        >
          {{ item.label }}
        </Button>
      </li>
    </template>
  </ul>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';

// https://community.algolia.com/instantsearch.js/v2/connectors/connectSortBySelector.html
import { connectSortBy } from 'instantsearch.js/es/connectors';

import { Button, sizes as buttonSizes, colors as buttonColors } from '@components/Buttons';

export default {
  name: 'SortBy',
  components: { Button },
  mixins: [createWidgetMixin({ connector: connectSortBy })],

  props: {
    items: {
      type: Array,
      required: true,
    },
    hideDefault: {
      type: Boolean,
      default: false,
    },
    buttonColor: {
      type: String,
      default: buttonColors.DEFAULT,
    },
  },

  computed: {
    widgetParams() {
      return {
        items: this.items,
      };
    },
  },

  watch: {
    'state.currentRefinement'(newRefinement, oldRefinement) {
      if (newRefinement !== oldRefinement) {
        this.$emit('sortChanged', this.items[0].value === newRefinement);
      }
    },
  },

  created() {
    this.buttonSizes = buttonSizes;
    this.buttonColors = buttonColors;
  },

  methods: {
    reset() {
      if (this.state && this.items.length > 0 && this.currentRefinement !== this.items[0].value) {
        this.state.refine(this.items[0].value);
      }
    },
    onClick(item) {
      this.currentRefinement = item.value;
      this.state.refine(item.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.SortBy {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  padding-left: 0;

  @media only screen and (min-width: $tablet) {
    padding-left: auto;
  }
}
.SortBy__item {
  display: block;
}
</style>
