<template>
  <div class="SearchSuggestions">
    <AisQueryRuleCustomData :transform-items="transformRuleItems" />
    <h2 v-if="title" class="SearchSuggestions__title">{{ title }}</h2>
    <!-- Brand suggestion -->

    <HitsIndex
      v-slot="{ hit }"
      tag="ul"
      :index-name="algoliaIndices.brands"
      hits-class="TagList TagList--dark"
      class="SearchSuggestions__tags"
      :auto-hide="true"
      @change="onBrandChange"
    >
      <!-- Rule suggestions -->

      <li v-for="(suggestion, index) in ruleSuggestions" :key="index" class="TagList__tag">
        <a :href="suggestion.url" :title="suggestion.name"
          ><i v-if="suggestion.icon" :class="suggestion.icon"></i> {{ suggestion.name }}</a
        >
      </li>

      <!-- Query suggestions -->
      <HitsIndex
        v-slot="{ hits }"
        hits-tag="ul"
        :index-name="algoliaIndices.querySuggestions"
        hits-class="TagList TagList--dark"
        class="SearchSuggestions__tags"
        :auto-hide="true"
        :limit="query.length === 0 ? 20 : 10"
        @change="onQueryChange"
      >
        <!-- Brand pages suggestions -->
        <li v-if="query.length > 1 && hit" class="TagList__tag">
          <a v-if="hit.logo && hit.logo !== ''" :href="hit.url" :title="hit.name"
            ><span>{{ $t('search-in') }} </span><img :src="hit.logo" :alt="hit.name"
          /></a>
          <a v-else :href="hit.url" :title="hit.name"
            >{{ $t('search-in') }} <mark>{{ hit.name }}</mark></a
          >
        </li>
        <!-- Empty query suggestions -->
        <li v-if="query.length === 0 && settings.mode !== 'b2b'" class="TagList__tag">
          <a href="/butikker" :title="$t('opening-hours')">{{
            $t('opening-hours').toLowerCase()
          }}</a>
        </li>
        <!-- Licensplate suggestion -->
        <li v-if="couldBeLicensplate" class="TagList__tag">
          <a href="" :title="$t('search-car')" @click.prevent="gotoCar"
            >{{ $t('search-car') }} <mark>{{ query.toUpperCase() }}</mark></a
          >
        </li>
        <template v-for="queryHit in hits">
          <li
            v-if="
              queryHit.query.toLowerCase() !== brandSuggestion.toLowerCase() &&
                queryHit.query.toLowerCase() !== query.toLowerCase()
            "
            :key="queryHit.objectId"
            class="TagList__tag"
          >
            <a
              :href="createSearchUrl(queryHit.query)"
              @click.stop.prevent="$emit('query-selected', queryHit.query)"
            >
              <AisHighlight attribute="query" :hit="queryHit" :class-names="{ 'ais-Highlight': '' }"
            /></a>
          </li>
        </template>
      </HitsIndex>
    </HitsIndex>
  </div>
</template>

<script>
import { AisHighlight, AisQueryRuleCustomData } from 'vue-instantsearch';
import { mapActions, mapGetters } from 'vuex';

import Cookies from 'js-cookie';

import { getByRef } from '@services/thansen/products';

import settings from '@settings';
import { createSearchUrl } from '@algolia';

import { HitsIndex } from '@components/Search';

export default {
  name: 'Suggestions',
  components: {
    AisHighlight,
    HitsIndex,
    AisQueryRuleCustomData,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: function() {
    return {
      licensplateTest: /^[A-Za-z]{2}[0-9]{5}$/,
      carsNotFound: [],
      settings: settings,
      algoliaIndices: settings.search.indices,
      createSearchUrl: createSearchUrl,
      brandSuggestion: '',
      nbQuery: 0,
      ruleSuggestions: [],
    };
  },
  computed: {
    // Checks if the search query could be a licensplate.
    couldBeLicensplate() {
      return this.carsNotFound.indexOf(this.query) === -1
        ? this.licensplateTest.test(this.query)
        : false;
    },
    nbSuggestions() {
      let count = this.nbQuery;
      if (this.brandSuggestion) count += 1;
      if (this.couldBeLicensplate) count += 1;
      count += this.ruleSuggestions.length;
      return count;
    },
    ...mapGetters('cardata', ['getSearchMatch']),
  },
  watch: {
    // whenever question changes, this function will run
    nbSuggestions: function(newCount, oldCount) {
      this.$emit('change', { nbSuggestions: this.nbSuggestions });
    },
  },
  mounted() {},
  methods: {
    ...mapActions('cardata', ['getByLicensePlate']),
    updateLegacyCookie(t, r) {
      let cookieBstr = `t=${t}`;
      if (typeof r !== 'undefined') {
        cookieBstr += `&r=${r}`; // HOTFIX MVN+KBA. New cars redirect fail if the licenseplate (r) is in the cookie.
      }

      Cookies.set('b', cookieBstr, { expires: 240 });
    },
    gotoCar(e) {
      this.getByLicensePlate(this.query)
        .then(status => {
          if (this.getSearchMatch && this.getSearchMatch.url) {
            // Updates the legacy cookie. !Hotfix #01!
            this.updateLegacyCookie(this.getSearchMatch.vehicle_id, this.licenseplate);
            //Tracking.addEvent('CarSearch', 'Search licenseplate', 'Success', 1);
            window.location = this.getSearchMatch.url;
          }
        })
        .catch(err => {
          this.carsNotFound.push(this.query);
          console.log(err);
        });
    },

    onBrandChange({ hit }) {
      if (hit && hit.name) this.brandSuggestion = hit.name;
      else this.brandSuggestion = '';
    },
    onQueryChange({ nbHits }) {
      this.nbQuery = nbHits;
      this.$emit('change', { nbSuggestions: this.nbSuggestions });
    },
    transformRuleItems(items) {
      let ruleSuggestions = [];

      if (items[0] && items[0].hasOwnProperty('suggestions')) {
        ruleSuggestions = [...ruleSuggestions, ...items[0].suggestions];
      }

      this.ruleSuggestions = ruleSuggestions;
      if (this.ruleSuggestions.length > 0) {
        this.$emit('change', { nbSuggestions: this.nbSuggestions });
      }
    },
  },
};
</script>
