<template functional>
  <BlockCollapse title="Kategori" :expanded="true">
    <AisHierarchicalMenu
      :attributes="['al_menu.lvl0', 'al_menu.lvl1', 'al_menu.lvl2', 'al_menu.lvl3']"
      :limit="999"
      :class-names="{
        'ais-HierarchicalMenu': 'HierarchicalMenu',
        'ais-HierarchicalMenu-list': 'HierarchicalMenu__list',
        'ais-HierarchicalMenu-item': 'HierarchicalMenu__item',
        'ais-HierarchicalMenu-item--selected': 'is-selected',
        'ais-HierarchicalMenu-link': 'HierarchicalMenu__link',
        'ais-HierarchicalMenu-label': 'HierarchicalMenu__label',
        //'ais-HierarchicalMenu-count': $options.countClass(),
        'ais-HierarchicalMenu-count': 'HierarchicalMenu__count--hidden',
      }"
    />

    <!--<RefinementList attribute="menu.lvl0" />-->
  </BlockCollapse>
</template>

<script>
import settings from '@settings';
export default {
  name: 'HierarchicalMenu',
  functional: true,
  countClass: () => {
    return ['HierarchicalMenu__count'].join(' ');
  },
};
</script>
