// Read more on connectors here: https://community.algolia.com/instantsearch.js/v2/connectors/connectAutocomplete.html#struct-Index
import { createWidgetMixin } from 'vue-instantsearch';
import { connectCurrentRefinements } from 'instantsearch.js/es/connectors';

export default {
  name: 'AlgoliaCurrentRefinements',
  mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],
  props: {
    // no default because included and excluded are incompatible
    // eslint-disable-next-line vue/require-default-prop
    includedAttributes: {
      type: Array,
    },
    // no default because included and excluded are incompatible
    // eslint-disable-next-line vue/require-default-prop
    excludedAttributes: {
      type: Array,
    },
    transformItems: {
      type: Function,
      default(items) {
        return items;
      },
    },
  },
  computed: {
    refinements() {
      return this.state ? this.state.items : [];
    },

    refine() {
      return this.state ? this.state.refine : undefined;
    },

    createURL() {
      return this.state ? this.state.createURL : undefined;
    },

    totalRefinementAmount() {
      if (this.state) {
        let amount = 0;
        this.state.items.forEach(item => {
          amount += item.refinements.length;
        });

        return amount;
      }

      return 0;
    },
  },
  render() {
    return this.$scopedSlots.default({
      refinements: this.refinements,
      refine: this.refine,
      createURL: this.createURL,
      totalRefinementAmount: this.totalRefinementAmount,
    });
  },
};
