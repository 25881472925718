var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasKeys)?_c('div',[_c('BlockCollapse',{attrs:{"title":_vm.$t('specifications'),"expanded":true}},[_vm._l((_vm.filters),function(ark,arkPropertyName){return [(!_vm.isSheetExcluded(arkPropertyName))?[_vm._l((ark),function(felt,feltPropertyName){return [(!_vm.isFieldExcluded(arkPropertyName, feltPropertyName))?[(felt.type == 1)?[_c('AisRefinementList',{key:feltPropertyName,attrs:{"show-more":"","show-more-limit":40,"sort-by":_vm.sortNumericAndAlphabeticallyAsc,"attribute":'sheets.' + arkPropertyName + '.' + feltPropertyName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var canToggleShowMore = ref.canToggleShowMore;
                var toggleShowMore = ref.toggleShowMore;
                var isShowingMore = ref.isShowingMore;
                var canRefine = ref.canRefine;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(items.length > 1),expression:"items.length > 1"}]},[(canRefine)?_c('ChoiceGroup',{attrs:{"label":felt.name,"unit":felt.unit,"items":items,"refine-method":refine,"should-transition":true}}):_vm._e(),(canToggleShowMore)?_c('div',{staticClass:"ShowMoreButton"},[_c('button',{staticClass:"Button Button--small Button--light",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? _vm.$t('show-more') : _vm.$t('show-less'))+" ")])]):_vm._e()],1)]}}],null,true)})]:_vm._e(),(felt.type == 2 || felt.type == 3)?[_c('AisMenu',{key:feltPropertyName,attrs:{"limit":120,"sort-by":['name:asc'],"attribute":'sheets.' + arkPropertyName + '.' + feltPropertyName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var refine = ref.refine;
                var items = ref.items;
                var canRefine = ref.canRefine;
return [_c('div',[(canRefine)?[_c('Select',{attrs:{"label":felt.name,"action":refine,"items":items,"unit":felt.unit}})]:_vm._e()],2)]}}],null,true)})]:_vm._e(),(felt.type == 4)?[_c('AisRefinementList',{key:feltPropertyName,attrs:{"show-more":"","limit":20,"show-more-limit":40,"sort-by":_vm.sortNumericAndAlphabeticallyAsc,"attribute":'sheets.' + arkPropertyName + '.' + feltPropertyName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var canToggleShowMore = ref.canToggleShowMore;
                var toggleShowMore = ref.toggleShowMore;
                var isShowingMore = ref.isShowingMore;
                var canRefine = ref.canRefine;
return [_c('div',[(canRefine)?_c('TagGroup',{attrs:{"label":felt.name,"unit":felt.unit,"items":items,"refine-method":refine,"should-transition":"true"}}):_vm._e(),(canToggleShowMore)?_c('div',{staticClass:"ShowMoreButton"},[_c('button',{staticClass:"Button Button--small Button--light",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? _vm.$t('show-more') : _vm.$t('show-less'))+" ")])]):_vm._e()],1)]}}],null,true)})]:_vm._e(),(felt.type == 5 || felt.type == 6)?[_c('AisRangeInput',{key:feltPropertyName,attrs:{"attribute":'sheets.' + arkPropertyName + '.' + feltPropertyName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var currentRefinement = ref.currentRefinement;
                var range = ref.range;
                var refine = ref.refine;
return [_c('Slider',{attrs:{"label":felt.name,"value":_vm.toValue(currentRefinement, range),"action":function (value) { return refine({ min: value[0], max: value[1] }); },"min":range.min,"max":range.max,"unit":felt.unit}})]}}],null,true)})]:_vm._e(),(felt.type == 8)?[_c('AisToggleRefinement',{key:feltPropertyName,staticClass:"FirstGridChild",attrs:{"on":true,"attribute":'sheets.' + arkPropertyName + '.' + feltPropertyName,"label":felt.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var canRefine = ref.canRefine;
                var value = ref.value;
                var refine = ref.refine;
                var createURL = ref.createURL;
return [_c('div',[(canRefine)?_c('div',{staticClass:"Input"},[_c('Toggle',{attrs:{"label":felt.name,"selected":value.isRefined,"amount":value.count || 0,"action":function () { return refine(value); }}})],1):_vm._e()])]}}],null,true)})]:_vm._e()]:_vm._e()]})]:_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }