var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TopbarSearch"},[_c('InstantSearch',{attrs:{"enable-routing":false,"contexts":['location_sitebar'],"enabled":_vm.activated,"analytics-tags":['location_sitebar']}},[_c('SearchBox',{ref:"searchbox",attrs:{"show-loading-indicator":"","delay":500,"role":_vm.eventRoles.SEARCH_DROPDOWN},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var query = ref.query;
      var nbHits = ref.nbHits;
      var nbPages = ref.nbPages;
      var refine = ref.refine;
      var goSearch = ref.goSearch;
      var ruleData = ref.ruleData;
      var stalled = ref.stalled;
return [(!_vm.drawerToogle)?_c('button',{staticClass:"TopbarSearch__toggle js-search-toggle",attrs:{"id":'DrawerToggle' + _vm._uid,"type":"button"}},[_c('i',{staticClass:"fal fa-search",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('Drawer',{staticClass:"TopbarSearch__content",attrs:{"close-label":_vm.$t('close'),"toggle-selector":".js-search-toggle","active-watch-selector":_vm.drawerToogle ? _vm.drawerToogle : '#' + 'DrawerToggle' + _vm._uid},on:{"scrolled":_vm.onDrawerScrolledChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var isActive = ref.isActive;
      var isOpen = ref.isOpen;
return [_c('div',{staticClass:"TopbarSearch__input"},[_c('div',{staticClass:"Input Input--search Input--searchButton Input--medium Input--grey Input--tight"},[_c('div',{staticClass:"Input__fieldWrap"},[_c('button',{staticClass:"Input__searchButton",on:{"click":function($event){return goSearch(query)}}},[_c('div',{staticClass:"Input__spinner"},[_c('svg',{staticClass:"Spinner",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 100 100"}},[_c('circle',{attrs:{"xmlns":"http://www.w3.org/2000/svg","cx":"50","cy":"50","fill":"none","stroke":"#ff708e","stroke-width":"8","r":"30","stroke-dasharray":"70"}})])]),_c('i',{staticClass:"far fa-search"})]),_c('form',{attrs:{"autocomplete":"off","method":"post","action":""}},[_c('input',{ref:"SearchInput",attrs:{"placeholder":_vm.$t('search-placeholder'),"type":"search","name":"search","autocomplete":"off","autocorrect":"off","autocapitalize":"none","spellcheck":"false","role":"combobox"},domProps:{"value":query},on:{"input":function($event){return refine($event.currentTarget.value)},"focus":_vm.onInputFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return goSearch($event.currentTarget.value)}}})]),_c('button',{staticClass:"Input__clear js-clear"})]),_c('span',{staticClass:"Input__message js-message"})])]),_c('SearchDropdown',{staticClass:"TopbarSearch__dropdown",attrs:{"is-open":isOpen || ((query || _vm.settings.mode !== 'b2b') && _vm.searchActive),"auto-position":isActive && isOpen,"query":query,"total-hits":nbHits,"max-items":isActive ? 40 : 9,"page-suggestions":ruleData('suggestions'),"extend-results":_vm.extendResults,"stalled":stalled,"refine-query":refine}}),(!isActive && _vm.searchActive)?_c('div',{staticClass:"TopbarSearch__backdrop",on:{"click":_vm.onBackdropClick}}):_vm._e()]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }