<template>
  <AisIndex :index-name="settings.search.indices.fireworks">
    <HitsContainer v-slot="{ hits, nbHits }" :limit="4" @change="event => $emit('change', event)">
      <div v-if="nbHits" class="SearchDropdown__hits FireworksHits">
        <div class="SearchDropdown__info">{{ $t('fireworks') }}:</div>
        <ul class="FireworksHits__list">
          <li v-for="(item, index) in hits" :key="item.item_number" class="FireworksHits__item">
            <ProductCardMini
              :title="item.title"
              :price="item.price"
              :image="item.image"
              :url="item.url"
            />
          </li>
        </ul>
        <a href="/fyrvaerkeri/" class="FireworksHits__link">{{
          $t('fireworks.view-assortment')
        }}</a>
      </div>
    </HitsContainer>
  </AisIndex>
</template>

<script>
import settings from '@settings';
import HitsContainer from '@containers/Algolia/Hits';
import ProductCardMini from './components/ProductCardMini';
import { AisIndex } from 'vue-instantsearch';

export default {
  name: 'FireworksHitsWrapper',
  components: {
    AisIndex,
    HitsContainer,
    ProductCardMini,
  },
  created() {
    this.settings = settings;
  },
};
</script>

<style lang="scss" scoped>
.FireworksHits {
  .FireworksHits__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    //grid-template-rows: 1fr 2fr 1fr;
    gap: 6px 6px;
    @include minBreakpoint(800) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .FireworksHits__item {
    display: flex;
  }
  .FireworksHits__link {
    outline: none;
    background: none;
    border: none;
    display: block;
    color: #3071a9;
    font-weight: bold;
    padding: 0.5em 0.8em;
  }
}
</style>

<i18n>
  {
    "da": {
      "fireworks.view-assortment": "Se hele sortimentet"
    },
    "no": {
      "fireworks.view-assortment": "Se hele utvalget"
    }
  }
</i18n>
