<template>
  <BlockCollapse title="Pris" :expanded="true">
    <AisRangeInput attribute="price">
      <div slot-scope="{ currentRefinement, range, refine }">
        <Slider
          :value="toValue(currentRefinement, range)"
          :action="value => refine({ min: value[0], max: value[1] })"
          :min="range.min"
          :max="range.max"
          unit="kr"
          :format-type="formatType"
        />
      </div>
    </AisRangeInput>

    <ul class="Checklist Checklist--gapped">
      <li v-if="settings.blackweek" class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="black" :on="true" :label="$t('blackweek')" color="black" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="is_new" :on="true" :label="$t('news')" color="new" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="is_sale" :on="true" :label="$t('sale')" color="sale" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="is_demo" :on="true" :label="$t('demo')" color="demo" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="label"
            :on="$t('clearance').toUpperCase()"
            :label="$t('clearance')"
            color="outlet"
          />
        </div>
      </li>
    </ul>
  </BlockCollapse>
</template>

<script>
import { BlockCollapse } from '@components/UI';
import RefinementToggle from '../RefinementToggle';
import Slider from '../Slider';
import { AisRangeInput } from 'vue-instantsearch';
import { InputType } from '@types/InputType';
import settings from '@settings';
import helpers from '@helpers';

export default {
  name: 'PriceFilter',

  components: {
    RefinementToggle,
    BlockCollapse,
    AisRangeInput,
    Slider,
  },

  data() {
    return {
      formatType: InputType.CURRENCY,
    };
  },
  computed: {
    blackLabel() {
      return settings.legacy.countryCode === 'NO' ? 'BLACK WEEK' : 'BLACK WEEKEND';
    },
  },
  created() {
    this.settings = settings;
  },
  methods: {
    toValue(value, range) {
      return helpers.algolia.rangeToValue(value, range);
    },
  },
};
</script>
