<template>
  <PreventBodyScroll :prevent="isOpen">
    <div v-show="isOpen" class="SearchDropdown">
      <div class="SearchDropdown__content">
        <div class="SearchDropdown__main">
          <div v-show="nbSuggestions" v-if="query.length === 0" class="SearchDropdown__info">
            {{ $tc('search-intro') }}:
          </div>

          <Suggestions
            v-show="nbSuggestions"
            :query="query"
            class="SearchDropdown__suggestions"
            @query-selected="q => refineQuery(q)"
            @change="onSuggestionsChange"
          ></Suggestions>

          <a
            v-if="
              settings.legacy.fireworks &&
                (settings.legacy.fireworks.presale || settings.legacy.fireworks.sale) &&
                ['mobileS', 'mobileM', 'mobileL', 'tablet'].indexOf(this.$mq) !== -1
            "
            class="SearchDropdown__suggestionsBanner"
            href="/fyrvaerkeri/"
            :title="$t('fireworks')"
          >
            <ImageHandler
              :source="
                `//cdn.thg.dk/DAT/dom/img/search_fyrvaerkeri_banner_mobile_${
                  settings.legacy.countryCode === 'NO' ? 'no' : 'da'
                }.jpg`
              "
              :title="$t('fireworks')"
            />
          </a>
          <FireworksHitsWrapper
            v-if="settings.search.indices.fireworks"
            @change="onFireworksChange"
          />

          <template v-if="settings.search.referenceSearchEnabled">
            <ReferenceHitsWrapper />
          </template>

          <template v-else>
            <RegularHitsWrapper />
          </template>

          <template v-if="noActualHits">
            {{ $t('search.noResults') }}
          </template>
        </div>

        <div class="SearchDropdown__secondary">
          <CategoryResults :on-group-change="onGroupChange" :group-hits="nbGroupHits" />
          <AisRefinementList
            v-slot="{ items, canToggleShowMore, toggleShowMore, isShowingMore }"
            show-more
            :transform-items="brandsTransformItems"
            attribute="brand.string"
          >
            <SearchBrands
              :items="items"
              :can-toggle-show-more="canToggleShowMore"
              :toggle-show-more="toggleShowMore"
              :is-showing-more="isShowingMore"
            />
          </AisRefinementList>
        </div>
      </div>
    </div>
  </PreventBodyScroll>
</template>

<script>
import { AisRefinementList, AisIndex } from 'vue-instantsearch';
import { mapActions, mapState, mapGetters } from 'vuex';

import { GroupCard } from '@components/Cards';
import { SearchResultCompact, Hits } from '@components/Search';

import PreventBodyScroll from '@components/PreventBodyScroll';
import { Suggestions, ReferenceHits } from '@components/Search';

import SearchBrands from './components/SearchBrands';
import CategoryResults from './components/CategoryResults';
import ReferenceHitsWrapper from './components/ReferenceHitsWrapper';
import RegularHitsWrapper from './components/RegularHitsWrapper';
import FireworksHitsWrapper from './components/FireworksHitsWrapper';
import ImageHandler from '@components/ImageHandler';

import tracking from '@tracking';
import settings from '@settings';

import { brandStringToObject } from '@helpers/algolia';

export default {
  name: 'SearchDropdown',

  components: {
    AisRefinementList,
    GroupCard,
    Suggestions,
    AisIndex,
    Hits,
    SearchResultCompact,
    SearchBrands,
    CategoryResults,
    ReferenceHits,
    ReferenceHitsWrapper,
    RegularHitsWrapper,
    FireworksHitsWrapper,
    PreventBodyScroll,
    ImageHandler,
  },

  props: {
    query: {
      type: String,
      default: '',
    },
    pageSuggestions: {
      type: Array,
      default() {
        return [];
      },
    },

    totalHits: {
      // total nbHits for products
      type: Number,
      default: 0,
    },
    autoPosition: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 4,
    },
    extendResults: {
      type: Boolean,
      default: false,
    },
    stalled: {
      type: Boolean,
      defalut: true,
    },
    refineQuery: {
      type: Function,
      required: true,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      nbSuggestions: 0,
      nbGroupHits: 0,
      nbRefrences: 0,
      nbFireworks: 0,
      refrenceStatus: '',
    };
  },

  computed: {
    searchUrl() {
      return '/soeg/?query=' + this.query;
    },

    hasAnyHits() {
      return (
        this.totalHits > 0 || this.nbSuggestions > 0 || this.nbGroupHits > 0 || this.nbRefrences > 0
      );
    },

    noActualHits() {
      return (
        this.query !== '' &&
        this.totalHits === 0 &&
        this.nbGroupHits === 0 &&
        this.nbRefrences === 0
      );
    },
  },
  watch: {
    isOpen(open) {},
  },
  created() {
    this.settings = settings;
  },

  methods: {
    brandsTransformItems(items) {
      return items.map(item => {
        return {
          ...item,
          brand: brandStringToObject(item.value),
        };
      });
    },
    onSuggestionsChange({ nbSuggestions }) {
      this.nbSuggestions = nbSuggestions;
    },
    onGroupChange({ nbHits }) {
      this.nbGroupHits = nbHits;
    },
    onReferenceChange({ nbHits }) {
      this.nbRefrences = nbHits;
    },
    onFireworksChange({ nbHits }) {
      this.nbFireworks = nbHits;
    },
    onReferenceStatus({ status }) {
      this.refrenceStatus = status;
    },
    onNoHits(e) {
      if (!this.hasAnyHits && this.refrenceStatus === 'updated' && e && e.query) {
        tracking.addEvent('SoegV4', 'NullHits', e.query);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchDropdown {
  .SearchDropdown__suggestionsBanner {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
