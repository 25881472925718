<template>
  <div>
    <AisRefinementList
      v-slot="{ items, refine, canToggleShowMore, toggleShowMore, isShowingMore }"
      show-more
      :attribute="attribute"
    >
      <ListTransition v-show="items.length > 0" tag="ul" class="Checklist">
        <li v-for="(item, index) in items" :key="item.value" class="Checklist__item">
          <div class="Checklist__itemInner">
            <div class="Input Input--tight" @click.prevent="refine(item.value)">
              <input
                :id="'filter_category_' + index"
                type="checkbox"
                :name="'filter_category_' + index"
                :checked="item.isRefined"
              />
              <label :for="'filter_category_' + index">
                {{ item.label }}
                <div class="Input__count">{{ item.count.toLocaleString() }}</div>
              </label>
            </div>
          </div>
        </li>
      </ListTransition>

      <div v-if="canToggleShowMore" class="Checklist__showMore">
        <button
          class="Button Button--small Button--transparent"
          :disabled="!canToggleShowMore"
          @click="toggleShowMore"
        >
          {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
        </button>
      </div>
    </AisRefinementList>
  </div>
</template>

<script>
import { AisRefinementList } from 'vue-instantsearch';
import { ListTransition } from '@transitions';
export default {
  name: 'RefinementList',

  components: {
    AisRefinementList,
    ListTransition,
  },

  props: {
    attribute: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.6s;
}

.flip-list-item {
  transition: all 0.6s;
  display: inline-block;
  margin-right: 10px;
}
.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.flip-list-leave-active {
  position: absolute;
}
</style>
