<template>
  <AisToggleRefinement
    v-slot="{ value, refine }"
    :attribute="attribute"
    :on="on"
    :label="label"
    class="tester"
  >
    <Choice
      :label="label"
      :selected="value.isRefined"
      :disabled="!value.count && !value.isRefined"
      :colorLabel="color"
      :action="e => refine(value)"
      :amount="value.onFacetValue.count && value.onFacetValue.count.toLocaleString()"
    />
  </AisToggleRefinement>
</template>

<script>
import Choice from '@components/Choice';
import { AisToggleRefinement } from 'vue-instantsearch';

export default {
  name: 'RefinementToggle',

  components: {
    Choice,
    AisToggleRefinement,
  },

  props: {
    attribute: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    on: {
      type: [Number, Boolean, String],
      default: null,
    },
    off: {
      // Not working! Disabled
      type: [Number, Boolean, String],
      default: null,
    },
    color: {
      type: String,
      default: '',
    },
  },

  methods: {
    refineChoice: (value, refine) => {
      return refine(!value);
    },
  },
};
</script>
