<template>
  <div v-observe-visibility="visibilityChanged">
    <img
      v-if="hasBeenShown && source"
      :class="['Image', isLoaded && 'Image--loaded']"
      :src="source"
      :alt="title"
      @load="imageOnload"
    />
    <i v-else class="far fa-image"></i>
  </div>
</template>

<script>
export default {
  name: 'ImageHandler',

  props: {
    source: {
      required: false,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLoaded: false,
      hasBeenShown: false,
    };
  },
  methods: {
    imageOnload() {
      this.isLoaded = true;
    },

    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.hasBeenShown = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Image {
  opacity: 0;
  transition: 100ms opacity linear;

  &--loaded {
    opacity: 1;
  }
}
</style>
