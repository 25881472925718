<template>
  <div class="SearchResult__labels">
    <div v-if="isNews" class="SearchResult__label">
      <div :class="['PriceLabel', 'PriceLabel--new', 'PriceLabel--small']">
        {{ $t('label-new') }}
      </div>
    </div>

    <template v-if="isDemo">
      <div class="SearchResult__label">
        <div :class="['PriceLabel', 'PriceLabel--demo', 'PriceLabel--small']">
          {{ label }}
        </div>
      </div>

      <div v-if="isOnSale" class="SearchResult__label">
        <div :class="['PriceLabel', 'PriceLabel--sale', 'PriceLabel--small']">
          {{ $t('save') }}
          <span v-if="isOnSale && settings.mode !== 'b2b'">{{ salePercent }}%</span>
        </div>
      </div>
    </template>

    <div
      v-else-if="
        label.toLowerCase() === 'black week' ||
          label.toLowerCase() === 'black weekend' ||
          label.toLowerCase() === 'black friday'
      "
      class="ProductCard__label"
    >
      <div :class="['PriceLabel', 'PriceLabel--bf']">
        {{ label }}
        <span v-if="isOnSale && settings.mode !== 'b2b'">{{ salePercent }}%</span>
      </div>
    </div>

    <div v-else-if="labelType && labelType !== 'new'" class="SearchResult__label">
      <div v-if="isClearance" :class="['PriceLabel', 'PriceLabel--outlet', 'PriceLabel--small']">
        {{ label || $t('label-clearance') }}
        <span v-if="isOnSale && settings.mode !== 'b2b'">-{{ salePercent }}%</span>
      </div>
      <div v-else :class="['PriceLabel', 'PriceLabel--' + labelType, 'PriceLabel--small']">
        {{ label || $t('label-' + labelType) }}
        <span v-if="isOnSale && settings.mode !== 'b2b'">-{{ salePercent }}%</span>
      </div>
    </div>

    <div v-if="popularity && popularity > 0.994" class="SearchResult__label">
      <div :class="['PriceLabel', 'PriceLabel--popular', 'PriceLabel--small']">
        {{ $t('popular') }}
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@settings';
export default {
  name: 'SearchResultCompactLabels',
  props: {
    isNews: {
      required: true,
    },
    isDemo: {
      required: true,
    },
    isOnSale: {
      required: true,
    },
    salePercent: {
      required: true,
    },
    label: {
      require: true,
    },
    labelType: {
      required: true,
    },
    isClearance: {
      required: true,
    },
    popularity: {
      required: false,
    },
  },
  data: () => {
    return {
      settings,
    };
  },
};
</script>
