<template>
  <BlockCollapse title="Intern" :expanded="true">
    <ul class="Checklist Checklist Checklist--gapped">
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.has_brand" :on="false" label="Uden mærke" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.has_image" :on="false" label="Uden billede" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.has_type" :on="false" label="Uden type" />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.in_stock_not" :on="true" label="Udsolgt" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.in_stock" :on="true" label="Ikke udsolgt" />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.in_stock_central_not"
            :on="true"
            label="Udsolgt (centrallager)"
          />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.in_stock_central"
            :on="true"
            label="Ikke udsolgt (centrallager)"
          />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.is_discontinued" :on="true" label="Varen udgår" />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.is_discontinued_not"
            :on="true"
            label="Varen udgår ikke"
          />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.is_outlet_not"
            :on="true"
            label="Ikke restmarked/outlet"
          />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="has_offers" :on="true" label="Har mængderabat" />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.has_sheets" :on="true" label="Har produktark" />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.has_sheets_not"
            :on="true"
            label="Har ikke produktark"
          />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.has_filterable_sheets"
            :on="false"
            label="Har ikke produktark (filtrerbare)"
          />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="pickup_only" :on="true" label="Kan kun afhentes" />
        </div>
      </li>

      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.has_net_weight_not"
            :on="true"
            label="Har ikke nettovægt"
          />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle
            attribute="al_toggles.has_gross_weight_not"
            :on="true"
            label="Har ikke bruttovægt"
          />
        </div>
      </li>
      <li class="Checklist__item">
        <div class="Checklist__itemInner">
          <RefinementToggle attribute="al_toggles.has_ean_not" :on="true" label="Har ikke EAN" />
        </div>
      </li>
    </ul>
  </BlockCollapse>
</template>

<script>
import RefinementToggle from '../RefinementToggle';
import { BlockCollapse } from '@components/UI';
export default {
  name: 'TestmodeFilter',
  components: {
    RefinementToggle,
    BlockCollapse,
  },
};
</script>

<style></style>
