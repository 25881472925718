<script>
import settings from '@settings';
import helpers from '@helpers';
import Price from '@components/Price';
import VueSlider from 'vue-slider-component';
import { InputType } from '@types/InputType';
import Input, { InputSize } from '@components/Input';
import './default.scss';

export default {
  name: 'Slider',
  components: {
    Input,
    VueSlider,
  },
  props: {
    max: {
      required: true,
      type: Number,
    },

    min: {
      required: true,
      type: Number,
    },

    action: {
      default: value => {
        if (settings.testmode) {
          console.log(
            'Value recieved. Remember to apply a method on the "action" prop for handling value change. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },

    value: {
      default: () => [0, 100],
      type: [Array, Number],
    },

    unit: {
      required: false,
      default: '',
      type: String,
    },

    formatType: {
      required: false,
      default: InputType.TEXT,
    },

    label: {
      required: false,
      default: '',
    },

    showInputs: {
      required: false,
      default: true,
      type: Boolean,
    },

    showTooltip: {
      required: false,
      default: true,
      type: Boolean,
    },

    showValuesAsText: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      _value: undefined,
      sliderTimeout: undefined,
      sliderFormatter: value => {
        const unit = helpers.product.printIfHasUnit(this.unit);

        if (this.formatType === InputType.TEXT) {
          return value + unit;
        } else if (this.formatType === InputType.CURRENCY) {
          return this.$n(value, 'currency') + unit;
        } else if (this.formatType === InputType.NUMBER) {
          return this.$n(value, 'number') + unit;
        }
      },
    };
  },

  computed: {
    computedMax() {
      const { min, max } = this;
      return min === max ? max + 1 : max;
    },
    computedValue() {
      const { value, min, max } = this;
      return min === max ? [min, max + 1] : value;
    },
  },

  watch: {
    value(newValue) {
      this._value = this.value;
    },
  },

  created() {
    this._value = this.value;
  },

  beforeDestroy() {
    /* Clear the timeout */
    clearTimeout(this.sliderTimeout);
  },

  methods: {
    reset() {
      this.action([this.min, this.computedMax]);
    },

    update() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    setImmediate(value, prevValue) {
      // This is mostly used for when setting a value and then tabbing to the next input

      /* Here we should minimise the amount of set value with a timeout */
      clearTimeout(this.sliderTimeout);

      // Set action immediate
      this.action(this.validateInput(value, prevValue));

      // Force update
      if (value[1] > this.max || value[0] < this.min) {
        this.update();
      }
    },

    setSliderValue(value, prevValue, timeout = 350) {
      if (this.showValuesAsText) {
        // Setting value for internal use on showing values
        this._value = value;
        this.update();
      }

      /* Here we should minimise the amount of set value with a timeout */
      clearTimeout(this.sliderTimeout);

      /* Do the debounce */
      this.sliderTimeout = setTimeout(() => {
        this.action(this.validateInput(value, prevValue));
      }, timeout);
    },

    validateInput(value, prevValue) {
      if (typeof value === 'number') {
        // If input is a number rather than an array,
        // make simple checks for it to be inside scope
        if (value > this.max) {
          return this.max;
        } else if (value < this.min) {
          return this.min;
        }

        // If all is fine then return the value
        return value;
      } else {
        // Catch values, and make sure they don't exceed their respective min and max bound
        let fromValue = parseInt(value[0] < this.min ? this.min : value[0]);
        let toValue = parseInt(value[1] > this.max ? this.max : value[1]);

        if (value[0] !== prevValue[0]) {
          // Changed "from" value

          // Make sure values don't overlap the slider boundaries
          if (fromValue >= toValue) {
            if (fromValue >= this.max) {
              // If the from value pushes the to value
              fromValue = this.max - 1;
              toValue = this.max;
            } else {
              fromValue = value[0];
              toValue = fromValue + 1;
            }
          }
        } else {
          // Changed "to" value

          // Make sure values don't overlap the slider boundaries
          if (toValue <= fromValue) {
            if (toValue <= this.min) {
              // If the from value pushes the to value
              toValue = this.min + 1;
              fromValue = this.min;
            } else {
              toValue = value[1];
              fromValue = toValue - 1;
            }
          }
        }

        // Return array with scoped set value
        return [fromValue, toValue];
      }
    },
  },

  render(createElement, context) {
    const { value, _value, formatType, unit, computedValue } = this;

    const valueIsCurrency = formatType === InputType.CURRENCY;

    // Set suffix
    const suffix = helpers.product.printIfHasUnit(unit, true);

    const valuesAsText = (
      <div class="ValuesAsText">
        <span>
          {valueIsCurrency ? (
            <Price value={_value[0]} />
          ) : (
            helpers.manipulation.getInputFormatByType(_value[0], formatType, this)
          )}
          {!valueIsCurrency && unit !== '' ? ' ' + unit : ''}
        </span>
        <span>
          {valueIsCurrency ? (
            <Price value={_value[1]} />
          ) : (
            helpers.manipulation.getInputFormatByType(_value[1], formatType, this)
          )}
          {!valueIsCurrency && unit !== '' ? ' ' + unit : ''}
        </span>
      </div>
    );

    if (typeof value === 'number') {
      return (
        <div>
          {this.showInputs && (
            <div class="Input Input--small InputWrapper">
              {this.label && <label>{this.label}</label>}

              <Input
                value={value}
                min={this.min}
                max={this.computedMax}
                unit={suffix}
                type={InputType.NUMBER}
                size={InputSize.SMALL}
                action={newValue =>
                  this.setSliderValue([value, parseInt(newValue)], [...value], 750)
                }
                blurAction={newValue => this.setImmediate([value, parseInt(newValue)], [...value])}
                validationAction={newState => {
                  /*console.log('Ny state:', newState)*/
                }}
              />
            </div>
          )}

          <VueSlider
            value={value}
            onChange={this.setSliderValue}
            min={this.min}
            max={this.computedMax}
            silent={true}
            contained={true}
            tooltip={this.showTooltip ? 'active' : 'none'}
            tooltip-formatter={this.sliderFormatter}
          />

          {this.showValuesAsText && valuesAsText}
        </div>
      );
    } else {
      return (
        <div class="Input Input--tight">
          {this.label && <label>{this.label}</label>}

          {this.showInputs && (
            <div class="RangeInputWrapper">
              <div class="InputWrapper">
                <Input
                  value={computedValue[0]}
                  min={this.min}
                  max={this.computedMax}
                  unit={suffix}
                  type={InputType.NUMBER}
                  size={InputSize.SMALL}
                  action={newValue => {
                    this.setSliderValue([parseInt(newValue), value[1]], [...value], 750);
                  }}
                  blurAction={newValue => {
                    this.setImmediate([parseInt(newValue), value[1]], [...value]);
                  }}
                  validationAction={newState => {
                    /*console.log('Ny state:', newState)*/
                  }}
                />
              </div>
              <div class="InputWrapper">
                <Input
                  value={computedValue[1]}
                  min={this.min}
                  max={this.computedMax}
                  unit={suffix}
                  type={InputType.NUMBER}
                  size={InputSize.SMALL}
                  action={newValue =>
                    this.setSliderValue([value[0], parseInt(newValue)], [...value], 750)
                  }
                  blurAction={newValue =>
                    this.setImmediate([value[0], parseInt(newValue)], [...value])
                  }
                  validationAction={newState => {
                    /*console.log('Ny state:', newState)*/
                  }}
                />
              </div>
            </div>
          )}

          <VueSlider
            value={computedValue}
            onChange={this.setSliderValue}
            min={this.min}
            max={this.computedMax}
            silent={true}
            class="Slider color-prim-brand"
            contained={true}
            tooltip={this.showTooltip ? 'active' : 'none'}
            tooltip-formatter={this.sliderFormatter}
          />

          {this.showValuesAsText && valuesAsText}
        </div>
      );
    }
  },
};
</script>

<style scoped lang="scss">
input {
  color: $color-black !important;
}

.InputWrapper {
  position: relative;
  width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Slider {
  margin-top: 15px;
  height: 2px !important;
}

.RangeInputWrapper {
  display: flex;

  > .InputWrapper {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}

.ValuesAsText {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
</style>
