var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AisRefinementList',{attrs:{"show-more":"","searchable":"","limit":10,"attribute":"parent.name","show-more-limit":40,"sort-by":['isRefined', 'name:asc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
var items = ref.items;
var canToggleShowMore = ref.canToggleShowMore;
var toggleShowMore = ref.toggleShowMore;
var isShowingMore = ref.isShowingMore;
var searchForItems = ref.searchForItems;
return [(items)?_c('BlockCollapse',{attrs:{"title":"Gruppe navn","expanded":false}},[(canToggleShowMore || _vm.searchNameInput)?_c('Input',{attrs:{"id":"ParentNameFilterSearch","placeholder":"Søg gruppe navn"},on:{"input":function (value) { return searchForItems(value); }},model:{value:(_vm.searchNameInput),callback:function ($$v) {_vm.searchNameInput=$$v},expression:"searchNameInput"}}):_vm._e(),(_vm.searchNameInput && items.length === 0)?_c('p',[_vm._v(_vm._s(_vm.$t('search.noResults')))]):_vm._e(),_c('ChoiceGroup',{attrs:{"items":items,"refine-method":refine,"should-transition":"true"}}),(canToggleShowMore)?_c('div',{staticClass:"Checklist__showMore"},[_c('button',{staticClass:"Button Button--small Button--light",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? _vm.$t('show-more') : _vm.$t('show-less'))+" ")])]):_vm._e()],1):_vm._e()]}}])}),_c('AisRefinementList',{attrs:{"show-more":"","searchable":"","limit":4,"attribute":"parent.node_id","show-more-limit":40,"sort-by":['isRefined', 'name:asc']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
var items = ref.items;
var canToggleShowMore = ref.canToggleShowMore;
var toggleShowMore = ref.toggleShowMore;
var isShowingMore = ref.isShowingMore;
var searchForItems = ref.searchForItems;
return [(items)?_c('BlockCollapse',{attrs:{"title":"Gruppe node","expanded":false}},[(canToggleShowMore || _vm.searchNodeInput)?_c('Input',{attrs:{"id":"ParentNodeIdFilterSearch","placeholder":"Søg efter node id"},on:{"input":function (value) { return searchForItems(value); }},model:{value:(_vm.searchNodeInput),callback:function ($$v) {_vm.searchNodeInput=$$v},expression:"searchNodeInput"}}):_vm._e(),(_vm.searchNodeInput && items.length === 0)?_c('p',[_vm._v(_vm._s(_vm.$t('search.noResults')))]):_vm._e(),_c('ChoiceGroup',{attrs:{"items":items,"refine-method":refine,"should-transition":"true"}}),(canToggleShowMore)?_c('div',{staticClass:"Checklist__showMore"},[_c('button',{staticClass:"Button Button--small Button--light",attrs:{"disabled":!canToggleShowMore},on:{"click":toggleShowMore}},[_vm._v(" "+_vm._s(!isShowingMore ? _vm.$t('show-more') : _vm.$t('show-less'))+" ")])]):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }