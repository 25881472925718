<template>
  <div class="TopbarSearch">
    <InstantSearch
      :enable-routing="false"
      :contexts="['location_sitebar']"
      :enabled="activated"
      :analytics-tags="['location_sitebar']"
    >
      <SearchBox
        v-slot="{
          query,
          nbHits,
          nbPages,
          refine,
          goSearch,
          ruleData,
          stalled,
        }"
        ref="searchbox"
        show-loading-indicator
        :delay="500"
        :role="eventRoles.SEARCH_DROPDOWN"
      >
        <button
          v-if="!drawerToogle"
          :id="'DrawerToggle' + _uid"
          class="TopbarSearch__toggle js-search-toggle"
          type="button"
        >
          <i class="fal fa-search" aria-hidden="true"></i>
        </button>
        <Drawer
          v-cloak
          v-slot="{ isActive, isOpen }"
          class="TopbarSearch__content"
          :close-label="$t('close')"
          toggle-selector=".js-search-toggle"
          :active-watch-selector="drawerToogle ? drawerToogle : '#' + 'DrawerToggle' + _uid"
          @scrolled="onDrawerScrolledChange"
        >
          <div class="TopbarSearch__input">
            <div
              class="Input Input--search Input--searchButton Input--medium Input--grey Input--tight"
            >
              <div class="Input__fieldWrap">
                <button class="Input__searchButton" @click="goSearch(query)">
                  <div class="Input__spinner">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="Spinner">
                      <circle
                        xmlns="http://www.w3.org/2000/svg"
                        cx="50"
                        cy="50"
                        fill="none"
                        stroke="#ff708e"
                        stroke-width="8"
                        r="30"
                        stroke-dasharray="70"
                      ></circle>
                    </svg>
                  </div>
                  <i class="far fa-search"></i>
                </button>
                <form autocomplete="off" method="post" action="">
                  <input
                    ref="SearchInput"
                    :value="query"
                    :placeholder="$t('search-placeholder')"
                    type="search"
                    name="search"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="none"
                    spellcheck="false"
                    role="combobox"
                    @input="refine($event.currentTarget.value)"
                    @focus="onInputFocus"
                    @keydown.enter.prevent="goSearch($event.currentTarget.value)"
                  />
                </form>
                <button class="Input__clear js-clear"></button>
              </div>
              <span class="Input__message js-message"></span>
            </div>
          </div>
          <SearchDropdown
            :is-open="isOpen || ((query || settings.mode !== 'b2b') && searchActive)"
            class="TopbarSearch__dropdown"
            :auto-position="isActive && isOpen"
            :query="query"
            :total-hits="nbHits"
            :max-items="isActive ? 40 : 9"
            :page-suggestions="ruleData('suggestions')"
            :extend-results="extendResults"
            :stalled="stalled"
            :refine-query="refine"
          />
          <div
            v-if="!isActive && searchActive"
            class="TopbarSearch__backdrop"
            @click="onBackdropClick"
          ></div>
        </Drawer>
      </SearchBox>
    </InstantSearch>
  </div>
</template>

<script>
import { SearchDropdown, InstantSearch, SearchBox } from '@components/Search';
import Drawer from '@components/Drawer';

import eventRoles from '@services/eventRoles';

import settings from '@settings';

export default {
  name: 'TopBarSearch',

  components: {
    SearchDropdown,
    InstantSearch,
    SearchBox,
    Drawer,
  },

  props: {
    extendResults: {
      type: Boolean,
      default: false,
    },
    drawerToogle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      eventRoles,

      searchActive: false,
      activated: false,
      settings,
    };
  },

  watch: {
    searchActive(to, from) {
      if (!this.activated && to) {
        this.activateSearch();
      }
    },
  },

  methods: {
    onInputFocus() {
      this.searchActive = true;
    },
    onBackdropClick() {
      this.searchActive = false;
    },
    activateSearch() {
      this.activated = true;
      //this.$refs.searchbox.refine(); // not necessary
    },
    onDrawerScrolledChange(scrolled) {
      // Focus and blur depending on scroll position. Fixes iPhone issue where keyboard would lay on top of brand hits.
      if (scrolled) {
        this.$nextTick(() => this.$refs.SearchInput.blur());
      } else {
        this.$nextTick(() => this.$refs.SearchInput.focus());
      }
    },
  },
};
</script>
