<template>
  <div>
    <AisRefinementList
      v-slot="{ refine, items, canToggleShowMore, toggleShowMore, isShowingMore, searchForItems }"
      show-more
      searchable
      :limit="10"
      attribute="profiles"
      :show-more-limit="40"
      :sort-by="['isRefined', 'name:asc']"
    >
      <BlockCollapse v-show="searchInput || items.length > 0" title="Profiler" :expanded="false">
        <Input
          id="ProfileFilterSearch"
          v-model="searchInput"
          :placeholder="$t('search-in') + ' profiler'"
          @input="value => searchForItems(value)"
        />
        <p v-if="searchInput && items.length === 0">{{ $t('search.noResults') }}</p>

        <ChoiceGroup :items="items" :refine-method="refine" should-transition="true" />

        <div v-if="canToggleShowMore" class="Checklist__showMore">
          <button
            class="Button Button--small Button--light"
            :disabled="!canToggleShowMore"
            @click="toggleShowMore"
          >
            {{ !isShowingMore ? $t('show-more') : $t('show-less') }}
          </button>
        </div>
      </BlockCollapse>
    </AisRefinementList>
  </div>
</template>

<script>
import Choice from '@components/Choice';
import Select from '@components/Select';
import { ChoiceGroup } from '@components/Algolia';
import { SearchIndicator, searchIndicatorSizes, BlockCollapse } from '@components/UI';
import { AisRefinementList } from 'vue-instantsearch';
import { mapActions, mapGetters, mapState } from 'vuex';

import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'ProfileFilter',
  components: {
    Select,
    ChoiceGroup,
    Input,
    AisRefinementList,
    BlockCollapse,
    SearchIndicator,
  },

  data() {
    return {
      searchInput: '',
      items: [],
    };
  },

  computed: {},

  created() {
    this.searchIndicatorSizes = searchIndicatorSizes;
  },

  methods: {},
};
</script>
