var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AisIndex',{attrs:{"index-name":_vm.indexName}},[_vm._t("before"),_c('Hits',{class:_vm.hitsClass,attrs:{"tag":_vm.tag,"limit":_vm.limit},on:{"change":function (e) { return _vm.$emit('change', e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hit = ref.hit;
var hits = ref.hits;
var nbHits = ref.nbHits;
var insights = ref.insights;
var trackClick = ref.trackClick;
var trackConvension = ref.trackConvension;
return [_vm._t("default",null,{"hit":hit,"hits":hits,"nbHits":nbHits,"insights":insights,"trackClick":trackClick,"trackConvension":trackConvension})]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }