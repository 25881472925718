var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PreventBodyScroll',{attrs:{"prevent":_vm.isOpen}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"SearchDropdown"},[_c('div',{staticClass:"SearchDropdown__content"},[_c('div',{staticClass:"SearchDropdown__main"},[(_vm.query.length === 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nbSuggestions),expression:"nbSuggestions"}],staticClass:"SearchDropdown__info"},[_vm._v(" "+_vm._s(_vm.$tc('search-intro'))+": ")]):_vm._e(),_c('Suggestions',{directives:[{name:"show",rawName:"v-show",value:(_vm.nbSuggestions),expression:"nbSuggestions"}],staticClass:"SearchDropdown__suggestions",attrs:{"query":_vm.query},on:{"query-selected":function (q) { return _vm.refineQuery(q); },"change":_vm.onSuggestionsChange}}),(
            _vm.settings.legacy.fireworks &&
              (_vm.settings.legacy.fireworks.presale || _vm.settings.legacy.fireworks.sale) &&
              ['mobileS', 'mobileM', 'mobileL', 'tablet'].indexOf(this.$mq) !== -1
          )?_c('a',{staticClass:"SearchDropdown__suggestionsBanner",attrs:{"href":"/fyrvaerkeri/","title":_vm.$t('fireworks')}},[_c('ImageHandler',{attrs:{"source":("//cdn.thg.dk/DAT/dom/img/search_fyrvaerkeri_banner_mobile_" + (_vm.settings.legacy.countryCode === 'NO' ? 'no' : 'da') + ".jpg"),"title":_vm.$t('fireworks')}})],1):_vm._e(),(_vm.settings.search.indices.fireworks)?_c('FireworksHitsWrapper',{on:{"change":_vm.onFireworksChange}}):_vm._e(),(_vm.settings.search.referenceSearchEnabled)?[_c('ReferenceHitsWrapper')]:[_c('RegularHitsWrapper')],(_vm.noActualHits)?[_vm._v(" "+_vm._s(_vm.$t('search.noResults'))+" ")]:_vm._e()],2),_c('div',{staticClass:"SearchDropdown__secondary"},[_c('CategoryResults',{attrs:{"on-group-change":_vm.onGroupChange,"group-hits":_vm.nbGroupHits}}),_c('AisRefinementList',{attrs:{"show-more":"","transform-items":_vm.brandsTransformItems,"attribute":"brand.string"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var items = ref.items;
              var canToggleShowMore = ref.canToggleShowMore;
              var toggleShowMore = ref.toggleShowMore;
              var isShowingMore = ref.isShowingMore;
return [_c('SearchBrands',{attrs:{"items":items,"can-toggle-show-more":canToggleShowMore,"toggle-show-more":toggleShowMore,"is-showing-more":isShowingMore}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }