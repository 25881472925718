<template>
  <AisInstantSearch
    :index-name="algoliaIndices.products"
    :search-client="{ search: searchRequests, searchForFacetValues: searchForFacetValues }"
    :search-function="searchFunction"
    :routing="enableRouting ? routing : null"
    :insights-client="insightsClient"
    :stalled-search-delay="0"
  >
    <AisConfigure
      :hits-per-page.camel="hitsPerPage"
      :rule-contexts.camel="contexts"
      :user-token.camel="userToken"
      :optional-filters.camel="optionalFilters.split(',')"
      :filters="filters"
      :click-analytics.camel="true"
      :analytics-tags.camel="analyticsTags"
    />
    <slot />
  </AisInstantSearch>
</template>

<script>
import { AisInstantSearch, AisConfigure } from 'vue-instantsearch';

import { history as historyRouter } from 'instantsearch.js/es/lib/routers';
import { singleIndex as singleIndexMapping } from 'instantsearch.js/es/lib/stateMappings';

import algoliasearch from 'algoliasearch/lite';

export default {
  name: 'InstantSearch',

  components: {
    AisInstantSearch,
    AisConfigure,
  },

  props: {
    contexts: {
      type: Array,
      default() {
        return [];
      },
    },
    enableRouting: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: String,
      default: '',
    },
    optionalFilters: {
      type: String,
      default: '',
    },
    searchFunction: {
      type: Function,
      default: helper => {
        helper.search();
      },
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    analyticsTags: {
      type: Array,
      default() {
        return [];
      },
    },
    hitsPerPage: {
      type: Number,
      default: 44,
    },
  },

  data() {
    return {
      algoliaClient: algoliasearch('OD48YHEOTK', '62be5f66604910c596a0da1f567c3237'),
      algoliaIndices: window.algoliaIndices,
      insightsClient: window.aa,
      routing: {
        router: historyRouter(),
        stateMapping: singleIndexMapping(window.algoliaIndices.products), // Currently using varer*** index mapping.
      },

      customData: [],
      userToken: window.globalvar_userid || window.globalvar_c,
      helper: null,
    };
  },

  methods: {
    searchRequests(requests) {
      if (!this.enabled) {
        if (requests.every(({ params }) => !params.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              processingTimeMS: 0,
            })),
          });
        }
      }
      const newRequests = requests.map(request => {
        // test for empty string and change request parameter: analytics
        if (!request.params.query || request.params.query.length === 0) {
          request.params.analytics = false;
        }
        request.params.facetingAfterDistinct = true;
        return request;
      });

      return this.algoliaClient.search(newRequests);
    },
    searchForFacetValues: function(requests) {
      return this.algoliaClient.searchForFacetValues(requests);
    },
  },
};
</script>
